import { Button } from "antd";
import { RenameIcon, MoveFolderIcon, ShareIcon } from './icons/icons';
import {
  DeleteOutlined,
  DownloadOutlined,
  CopyOutlined,
  FolderOpenOutlined
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

const ActionBar = ({ selectedCount, selectedAction, setSelectedAction, performAction, disabledActions }: any) => {
  const { t } = useTranslation();

  const redBrickColor = '#E43D4F'; // Define your "red brick" color

  const actionButtonStyle = {
    margin: '0 8px',
    border: 'none',
  };

  const highlightedButtonStyle = {
    ...actionButtonStyle,
    backgroundColor: redBrickColor, // Apply the "red brick" color
    color: 'white', // Text/icon color
  };

  const toolbarStyle = {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#f0f2f5',
    borderBottom: '1px solid #d9d9d9',
    padding: '10px 24px',
    justifyContent: 'space-between'
  };

  // Function to determine the style of a button based on whether it's selected
  const getButtonStyle = (actionName: any) => {
    return actionButtonStyle;
    // return selectedAction === actionName ? highlightedButtonStyle : actionButtonStyle;
  };

  const actionSelection = (name: string) => {
    setSelectedAction(name);
    performAction(name);
  }

  return (
    <div style={toolbarStyle}>
      <span>{selectedCount} {t('dashboard.actionBar.selected')}</span>
      <div>
        {!disabledActions?.includes("open-file") && (
          <Button
            icon={<FolderOpenOutlined />}
            style={getButtonStyle('open-file')}
            onClick={() => actionSelection('open-file')}
          >
            {t('dashboard.actionBar.open')}
          </Button>
        )}

        <Button icon={<RenameIcon />} style={getButtonStyle('rename')} onClick={() => actionSelection('rename')}>
          {t('dashboard.actionBar.rename')}
        </Button>
        <Button icon={<CopyOutlined />} style={getButtonStyle('copy')} onClick={() => actionSelection('copy')}>
          {t('dashboard.actionBar.copy')}
        </Button>
        <Button icon={<MoveFolderIcon />} style={getButtonStyle('move')} onClick={() => actionSelection('move')}>
          {t('dashboard.actionBar.move')}
        </Button>
        <Button
          icon={<DeleteOutlined />}
          style={{ ...getButtonStyle('delete'), color: redBrickColor }}
          onClick={() => actionSelection('delete')}
        >
          {t('dashboard.actionBar.delete')}
        </Button>
        <Button icon={<DownloadOutlined />} style={getButtonStyle('download')} onClick={() => actionSelection('download')}>
          {t('dashboard.actionBar.download')}
        </Button>
        <Button disabled icon={<ShareIcon />} style={getButtonStyle('share')} onClick={() => actionSelection('share')}>
          {t('dashboard.actionBar.share')}
        </Button>
        {/* More buttons as needed */}
      </div>
    </div>
  );
};

export default ActionBar;
