import { useTranslation } from 'react-i18next';
import { Button, Divider } from "antd";
import {
  DeleteOutlined,
  RollbackOutlined
} from '@ant-design/icons';

const ActionBar = ({ selectedCount, selectedAction, setSelectedAction, performAction }: any) => {
  const { t } = useTranslation();
  const redBrickColor = '#E43D4F'; // This is an example, adjust the hex code as needed

  const actionButtonStyle = {
    margin: '0 8px',
    border: 'none',
  };

  const highlightedButtonStyle = {
    ...actionButtonStyle,
    backgroundColor: redBrickColor, // Apply the "red brick" color
    color: 'white', // If you want the text/icon color to be white or any other color
  };


  const toolbarStyle = {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#f0f2f5',
    borderBottom: '1px solid #d9d9d9',
    padding: '10px 24px',
    // justifyContent: 'space-between'
  };

  // Function to determine the style of a button based on whether it's selected
  const getButtonStyle = (actionName: any) => {
    return actionButtonStyle;
    // return selectedAction === actionName ? highlightedButtonStyle : actionButtonStyle;
  };
  const actionSelection = (name: string) => {
    setSelectedAction(name);
    performAction(name);
  }
  return (
    <div style={toolbarStyle}>
      <span>{selectedCount} {t('trash.actionBar.selected')}</span>
      <div>
        <Divider type="vertical" style={{ backgroundColor: 'GrayText' }} />
        <Button
          icon={<RollbackOutlined />}
          style={{ ...getButtonStyle('restore'), color: 'black' }}
          onClick={() => actionSelection('restore')}
        >
          {t('trash.actionBar.restore')}
        </Button>
        <Button
          icon={<DeleteOutlined />}
          style={{ ...getButtonStyle('delete'), color: redBrickColor }}
          onClick={() => actionSelection('delete')}
        >
          {t('trash.actionBar.delete')}
        </Button>
      </div>
    </div>
  );
};

export default ActionBar;
