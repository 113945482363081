import React, { useContext, useEffect, useState } from 'react';
import { Layout, Menu, Input, Avatar, Dropdown, Space, Button, Tooltip } from 'antd';
import { UserOutlined, GlobalOutlined } from '@ant-design/icons';
import { AppContext } from '../components/AppContext';
import { useTranslation } from 'react-i18next';
import { useUpdateUserLangPreference } from '../react-query/mutations/userLang';
import axiosInstance from '../react-query/axiosInstance';

const { Header } = Layout;
const { Search } = Input;

const DropDownMenu = () => {
  const { app } = useContext<any>(AppContext);
  const [userEmail, setUserEmail] = React.useState<string | undefined>(undefined);
  const [profileImage, setProfileImage] = React.useState<string | undefined>(undefined);
  const [profileMenuOptions, setProfileMenuOptions] = React.useState<any>(undefined);

  const getProfileMenuComponent = () => {
    if (app?.state?.user?.profileMenuOptions) {
      return (
        <Menu style={{
          border: 'none',
          boxShadow: 'none'
        }}>
          {app?.state?.user?.profileMenuOptions?.list?.map((item: any) => (
            <Menu.Item key={item.menuName} onClick={() => handleMenuItemClick(item)}>
              <Space>
                <img src={item.icon} alt="" />
                {item.menuName}
              </Space>
            </Menu.Item>
          ))}
        </Menu>
      );
    }
  };

  useEffect(() => {
    if (app?.state?.user?.picture) {
      setProfileImage(app?.state?.user?.picture);
      setUserEmail(app?.state?.user?.email);
    }
    if (app?.state?.user?.profileMenuOptions) {
      setProfileMenuOptions(getProfileMenuComponent());
    }
  }, [app?.state?.user?.picture, app?.state?.user?.profileMenuOptions]);

  const handleMenuItemClick = async (item: any) => {
    if (item.menuName === 'Sign Out') {
      await app.logout();
    } else {
      if (app?.state?.platformIsWeb) {
        // @ts-ignore
        window.open(item.menuLink, '_blank');
      }
      else {
        // @ts-ignore
        window.electron.openExternalLink(item.menuLink);
      }
    }
  };

  return (
    <div
      style={{
        padding: '16px',
        backgroundColor: '#FFFFFF',
        border: '1px solid #f0f0f0',
        boxShadow: '0 6px 16px 0 rgba(0, 0, 0, 0.08)',
        borderRadius: '24px',
      }}
    >
      <div style={{ marginBottom: '16px', textAlign: 'center' }}>
        <Avatar size={64} icon={<UserOutlined />} src={profileImage} />
      </div>
      <span style={{ fontSize: '12px', color: 'GrayText' }}>{userEmail}</span>
      <div style={{ borderBottom: '1px solid #f0f0f0', margin: '16px 0' }} />
      {profileMenuOptions}
    </div>
  );
};

const PageHeader = () => {
  const { app } = useContext<any>(AppContext);
  const [profileImage, setProfileImage] = React.useState<string | undefined>(undefined);
  const [searchQuery, setSearchQuery] = React.useState<string>('');
  const { i18n, t } = useTranslation();
  const updateLanguage = useUpdateUserLangPreference()

  useEffect(() => {
    if (app?.state?.user?.preferedLanguage && app?.state?.user?.preferedLanguage !== i18n.language) {
      i18n.changeLanguage(app?.state?.user?.preferedLanguage);
      axiosInstance.defaults.headers['x-lang'] = i18n.language;
    }
  }, [app?.state?.user?.preferedLanguage]);
  // console.log("app?.state?.user", app?.state?.user)
  // add axios instance header with `x-lang` as language
  const changeLanguage = async (lng: string) => {
    i18n.changeLanguage(lng);
    axiosInstance.defaults.headers['x-lang'] = i18n.language;
    await updateLanguage.mutateAsync(lng as any);
    if (app?.state?.refetchUserAndUpdateState) {
      await app?.state?.refetchUserAndUpdateState();
    }
    console.log('Language changed to:', lng, i18n);
  };


  // useEffect(() => {
  //   const handler = setTimeout(() => {
  //     setDebouncedSearchTerm(searchQuery);
  //   }, 500); // 500ms delay

  //   return () => {
  //     clearTimeout(handler);
  //   };
  // }, [searchQuery]);

  // useEffect(() => {
  //   if (!debouncedSearchTerm || debouncedSearchTerm?.length < 3) {
  //     return;
  //   }
  //   // if current location is not Dashboard or Search, redirect to Dashboard
  //   if (window.location.hash !== '#/dashboard' && window.location.hash !== '#/search') {
  //     window.location.hash = '#/dashboard';
  //   }
  //   // Update search term in app state
  //   app.setAppState({ ...app.state, searchTerm: debouncedSearchTerm });
  // }, [debouncedSearchTerm]);

  const handleSearch = (value: string) => {
    setSearchQuery(value);
    if (value.length >= 3) {
      if (window.location.hash !== '#/dashboard' && window.location.hash !== '#/search') {
        window.location.hash = '#/dashboard';
      }
      app.setAppState({ ...app.state, searchTerm: value });
    }
  };

  useEffect(() => {
    if (app?.state?.user?.picture) {
      setProfileImage(app?.state?.user?.picture);
    }
  }, [app?.state?.user?.picture]);

  const currentLanguage = i18n.language === 'en' ? 'English' : 'kor';

  const menu = (
    <Menu>
      <Menu.Item key="kor" onClick={() => changeLanguage('kor')}
        style={i18n.language === 'kor' ? { fontWeight: 'bold', backgroundColor: '#E43D4F', color: 'white' } : {}}
      >
        KOR
      </Menu.Item>
      <Menu.Item key="en" onClick={() => changeLanguage('en')}
        style={i18n.language === 'en' ? { fontWeight: 'bold', backgroundColor: '#E43D4F', color: 'white' } : {}}
      >
        ENG
      </Menu.Item>
    </Menu>
  );

  return (
    <Header
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '0 20px',
        background: 'white',
        color: 'black',
        borderBottom: '5px solid #f0f0f0',
      }}
    >
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <img src="favicon.ico" alt="Logo" style={{ width: '20px', height: '20px', marginRight: '12px' }} />
        <div style={{ fontWeight: 'bold', fontSize: '20px' }}>{t('header.title')}</div>
      </div>

      <div style={{
        display: 'flex', alignItems: 'center',
        maxWidth: '800 px',
      }}>
        <Space size="middle">
          <div style={{ flexGrow: 1, display: 'flex', justifyContent: 'center' }}>
            <Tooltip
              title={t('header.searchTooltip')}
              visible={searchQuery.length > 0 && searchQuery.length < 3}
            >
              <Search
                placeholder={t("header.search")}
                style={{ maxWidth: '400px' }}
                onSearch={handleSearch}
                onChange={(e) => setSearchQuery(e.target.value)}
                value={searchQuery}
              />
            </Tooltip>
          </div>
          <Dropdown overlay={menu} trigger={['click']}>
            <Button style={{
              backgroundColor: 'transparent',
              border: 'none',
              boxShadow: 'none',
              color: 'black',
              padding: '0',
            }} icon={<GlobalOutlined />} />
          </Dropdown>

          <Dropdown overlay={<DropDownMenu />} trigger={['click']} >
            <a onClick={(e) => e.preventDefault()}>
              <Avatar icon={<UserOutlined />} src={profileImage} />
            </a>
          </Dropdown>
        </Space>
      </div>
    </Header>
  );
};

export default PageHeader;
