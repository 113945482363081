import { useContext, useState } from 'react';
import { useQuery } from 'react-query';
import axios from '../react-query/axiosInstance';
import { Button, Collapse, Modal, Space, Table, Typography } from 'antd';
import { FolderOutlined, FileOutlined, ExportOutlined, UndoOutlined, DeleteOutlined } from '@ant-design/icons';
import { ColumnType } from 'antd/es/table';
import { SortOrder } from 'antd/es/table/interface';
import moment from 'moment';
import { Loader } from '../components/loader';
import { AppContext } from '../components/AppContext';
import { useCleanTrash, useDeleteTrashItem } from '../react-query/mutations/deleteTrash';
import { useRestoreTrashItem } from '../react-query/mutations/restoreTrashItem';
import { useNavigate } from 'react-router-dom';
import { DriveType } from '../components/ExternalDrives/types';
import { Trans, useTranslation } from 'react-i18next';

const { Panel } = Collapse;
const { Text } = Typography;

// Fetching function for API 1 (My Drive and Trash)
const fetchMyDriveData = async (query: any) => {
	const response = await axios.get('file/search-drive', { params: { query } });
	return response.data.data;
};

// Fetching function for API 2 (Google Drive)
const fetchGoogleDriveData = async (query: any) => {
	const response = await axios.get('google-drive/search-drive', { params: { query } });
	return response.data.data;
};

const ACTION_BAR_TYPES = {
	'myDrive': 'myDrive',
	'trash': 'trash',
	'googleDrive': 'googleDrive'
}

const getActionsForActionBar = (t: any, actionBarType: string, actionHandlers: any[]) => {
	if (actionBarType === ACTION_BAR_TYPES.myDrive) {
		return (<Space>
			<Button
				type="link"
				icon={<ExportOutlined />}
				onClick={() => {
					actionHandlers[0]();
				}}
				style={{ color: '#1890ff' }}
			>
				{t('search.open')}
			</Button>
			<Button
				type="link"
				icon={<FolderOutlined />}
				onClick={() => {
					actionHandlers[1]();
				}}
				style={{ color: '#595959' }}
			>
				{t('search.goToFolder')}
			</Button>
		</Space>)
	}
	else if (actionBarType === ACTION_BAR_TYPES.trash) {
		return (<Space>
			<Button
				type="link"
				icon={<UndoOutlined />}
				onClick={() => {
					actionHandlers[0]();
				}}
				style={{ color: '#1890ff' }}
			>
				{t('search.restore')}
			</Button>
			<Button
				type="link"
				icon={<DeleteOutlined />}
				onClick={() => {
					actionHandlers[1]();
				}}
				style={{ color: '#E43D4F' }}
			>
				{t('search.delete')}
			</Button>
		</Space>)
	}
	else if (actionBarType === ACTION_BAR_TYPES.googleDrive) {
		return (<Space>
			<Button
				type="link"
				icon={<FolderOutlined />}
				onClick={() => {
					actionHandlers[0]();
				}}
				style={{ color: '#595959' }}
			>
				{t('search.goToFolder')}
			</Button>
		</Space>)
	}
	else {
		return null;
	}
}
const ActionBar = ({ actionHandlers, actionBarType }: any) => {
	const { t, i18n } = useTranslation();
	return (<div
		style={{
			marginTop: '16px',
			display: 'flex',
			justifyContent: 'space-between',
			alignItems: 'center',
			padding: '8px 16px',
			backgroundColor: '#dcdcdc',
			borderRadius: '8px',
			marginBottom: '16px',
			boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
		}}
	>
		<div
			style={{
				display: 'flex',
				justifyContent: 'space-between',
				alignItems: 'center',

			}}
		>

			<Text strong style={{
				marginRight: 32,
			}}>
				{`1 ${t('search.selected')}`}
			</Text>
			<div style={{ width: '1px', height: 24, backgroundColor: 'black' }} />
		</div>
		{getActionsForActionBar(t, actionBarType, actionHandlers)}
		{/* vertical line */}

	</div>)
}

const SearchResults = ({ query, handleFileClick, handleOpenFolder }: any) => {
	const { app } = useContext<any>(AppContext);
	const navigate = useNavigate();
	const { t, i18n } = useTranslation();
	const [selectedMyDriveRow, setSelectedMyDriveRow] = useState<any>(null);
	const [selectedTrashRow, setSelectedTrashRow] = useState<any>(null);
	const [selectedGoogleDriveRow, setSelectedGoogleDriveRow] = useState<any>(null);
	const { mutateAsync: restoreFolder, isLoading: restoreFolderLoading } = useRestoreTrashItem('folder', null);
	const { mutateAsync: restoreFile, isLoading: restoreFileLoading } = useRestoreTrashItem('file', null);
	const { mutateAsync: deleteItem, isLoading: deleteItemLoading } = useDeleteTrashItem();

	const { data: myDriveData, isLoading: myDriveLoading, refetch: refetchMyDriveData } = useQuery(['myDrive', query], () => fetchMyDriveData(query), {
		refetchOnWindowFocus: false,
	});

	const { data: googleDriveData, isLoading: googleDriveLoading } = useQuery(['googleDrive', query], () => fetchGoogleDriveData(query), {
		refetchOnWindowFocus: false,
	});

	if (myDriveLoading || googleDriveLoading) {
		return <Loader isLoading={myDriveLoading || googleDriveLoading} children={null} />;
	}

	let googleDriveFiles: any = [];
	if (googleDriveData && Object.keys(googleDriveData).length > 0) {
		googleDriveFiles = Object.values(googleDriveData).reduce((acc: any, accountData: any) => {
			if (accountData?.files) {
				return acc.concat(accountData.files);
			}
			return acc;
		}, []);
	}


	// Define columns for the AntD Table with icons and inline styling
	const myDriveColumns: Array<ColumnType<any>> = [
		{
			title: t('search.columns.myDrive.name'),
			dataIndex: 'name',
			showSorterTooltip: false,
			key: 'name',
			sorter: (a, b) => a.name.localeCompare(b.name),
			render: (text, record) => (
				<Space direction="vertical" size={1}>
					<Space>
						{record.isFolder ? (
							<FolderOutlined rev={undefined} />
						) : (
							<FileOutlined rev={undefined} />
						)}
						{record?.handleClick ? (<Text
							onMouseEnter={(event: any) => {
								event.target.style.transition = 'transform 0.1s, text-shadow 0.1s';
								event.target.style.transform = 'translateZ(20px) scale(1.1)';
								event.target.style.perspective = '1000px';
								event.target.style.textShadow = `
                            1px 1px 2px rgba(0, 0, 0, 0.1),
                            2px 2px 4px rgba(0, 0, 0, 0.1),
                            3px 3px 6px rgba(0, 0, 0, 0.1),
                            4px 4px 8px rgba(0, 0, 0, 0.1),
                            5px 5px 10px rgba(0, 0, 0, 0.2)
                        `;

							}}
							onMouseLeave={(event: any) => {
								event.target.style.transform = 'none';
								event.target.style.textShadow = 'none';
							}}
							style={{
								cursor: 'pointer',
							}}
							onClick={() => {
								record?.handleClick(record)
							}}
						>{text}</Text>)

							:

							(<Text>{text}</Text>)
						}
					</Space>
					{record.systemPathName && (
						<Text
							type="secondary"
							style={{
								fontSize: 'small',
								color: '#5A5A5A',
								fontWeight: 'bold',
								backgroundColor: '#f0f0f0',
								padding: '2px 4px',
								borderRadius: '4px',
								textTransform: 'uppercase',
								letterSpacing: '0.5px'
							}}
						>
							{`myDrive${record?.systemPathName?.split('/')?.slice(0, -2).join(' > ')}`}
						</Text>
					)}
					{record.lastSystemPathFolderNames && (
						<Text
							type="secondary"
							style={{
								fontSize: 'small',
								color: '#5A5A5A',
								fontWeight: 'bold',
								backgroundColor: '#f0f0f0',
								padding: '2px 4px',
								borderRadius: '4px',
								textTransform: 'uppercase',
								letterSpacing: '0.5px'
							}}
						>
							{`myDrive${record?.lastSystemPathFolderNames?.split('/')?.slice(0, -2).join(' > ')}`}
						</Text>
					)}
				</Space>
			),
		},
		{
			title: t('search.columns.myDrive.updatedDate'),
			dataIndex: 'modifiedDateTime',
			showSorterTooltip: false,
			key: 'lastModified',
			defaultSortOrder: 'descend' as SortOrder,
			sorter: (a, b) => {
				if (a.type === 'folder') return 1;
				if (b.type === 'folder') return -1;
				return moment(a.modifiedDateTime).unix() - moment(b.modifiedDateTime).unix();
			},
			render: (text: string) => {
				if (i18n.language === 'en') {
					// return Aug, 12 2021 12:00
					return moment(text)?.local()?.format('MMM DD, YYYY HH:mm')
				}
				else {

					return moment(text)?.local()?.format('YYYY-MM-DD HH:mm')
				}
			},
		},
		{
			title: t('search.columns.myDrive.format'),
			dataIndex: 'mimeType',
			showSorterTooltip: false,
			key: 'mimeType',
			sorter: (a, b) => {
				const firstFormat = a.mimeType?.split('/')[1] || '';
				const secondFormat = b.mimeType?.split('/')[1] || '';
				return firstFormat.localeCompare(secondFormat);
			},
			render: (text, record) => {
				if (!text) return '';
				return record?.name?.split ? record?.name?.split('.').slice(-1).join('.') : '';
			}
		},
		{
			title: t('search.columns.myDrive.size'),
			dataIndex: 'size',
			showSorterTooltip: false,
			key: 'size',
			sorter: (a, b) => a.size - b.size,
			render: (text) => {
				if (!text) return '';

				if (text < 1024) {
					return `${text} B`;
				} else if (text < 1024 * 1024) {
					return `${(text / 1024).toFixed(2)} KB`;
				} else if (text < 1024 * 1024 * 1024) {
					return `${(text / (1024 * 1024)).toFixed(2)} MB`;
				} else {
					return `${(text / (1024 * 1024 * 1024)).toFixed(2)} GB`;
				}
			},
		}
	];

	const googleDriveColumns = [
		{
			title: t('search.columns.googleDrive.name'),
			dataIndex: 'name',
			showSorterTooltip: false,
			key: 'name',
			render: (text: string, record: any) => (
				<Text>{text}</Text>
			),
			sorter: (a: any, b: any) => a.name.localeCompare(b.name),
		},
		{
			title: t('search.columns.googleDrive.modifiedDate'),
			dataIndex: 'modifiedTime',
			showSorterTooltip: false,
			key: 'modifiedTime',
			defaultSortOrder: 'descend' as SortOrder,
			sorter: (a: any, b: any) => moment(a.modifiedTime).unix() - moment(b.modifiedTime).unix(),
			render: (text: string) => {
				if (i18n.language === 'en') {
					// return Aug, 12 2021 12:00
					return moment(text)?.local()?.format('MMM DD, YYYY HH:mm')
				}
				else {

					return moment(text)?.local()?.format('YYYY-MM-DD HH:mm')
				}
			},
		},
		{
			title: t('search.columns.googleDrive.format'),
			dataIndex: 'mimeType',
			key: 'mimeType',
			showSorterTooltip: false,
			sorter: (a: any, b: any) => a.mimeType.localeCompare(b.mimeType),
			render: (text: string) => {
				if (!text) return '';
				return text.split('.').pop();
			}
		},
		{
			title: t('search.columns.googleDrive.size'),
			dataIndex: 'quotaBytesUsed',
			showSorterTooltip: false,
			key: 'quotaBytesUsed',
			sorter: (a: any, b: any) => a.quotaBytesUsed - b.quotaBytesUsed,
			render: (text: string) => {
				if (!text) return '';
				const number = parseInt(text, 10);
				if (number < 1024) {
					return `${number} B`;
				} else if (number < 1024 * 1024) {
					return `${(number / 1024).toFixed(2)} KB`;
				} else if (number < 1024 * 1024 * 1024) {
					return `${(number / (1024 * 1024)).toFixed(2)} MB`;
				} else {
					return `${(number / (1024 * 1024 * 1024)).toFixed(2)} GB`;
				}
			},
		}
	];

	const onUpdate = async () => {
		try {
			await refetchMyDriveData();
		} catch (error) {

		}
	}
	const handleDelete = async () => {
		try {
			await deleteItem({ itemId: selectedTrashRow?.id, type: selectedTrashRow?.isFolder ? 'folder' : 'file' });
			await onUpdate();
		} catch (error) {
			console.log(error)
		}
		// unselect the row
		setSelectedTrashRow(null);

	}

	const handleRestore = async () => {
		try {
			if (selectedTrashRow.isFolder) {
				await restoreFolder({ itemId: selectedTrashRow.id });
				// unselect the row
				setSelectedTrashRow(null);

			} else {
				await restoreFile({ itemId: selectedTrashRow.id });
			}
			await onUpdate();
		} catch (error) {

		}
		// unselect the row
		setSelectedTrashRow(null);

	}

	const deleteConfirmation = async () => {
		const name = selectedTrashRow.name;
		Modal.confirm({
			title: t('search.deleteConfirmationTitle'),
			content: (<Trans i18nKey="search.deleteConfirmationContent" values={{ name: name }}></Trans>), //t('search.deleteConfirmationContent', { name }),
			okText: t('search.deleteForever'),
			okType: 'danger',
			cancelText: t('search.cancel'),
			onOk() {
				handleDelete();
			},
			onCancel() {
				// Do nothing
			},
		});
	};


	const restoreConfirmation = async () => {
		const itemName = selectedTrashRow.name;
		Modal.confirm({
			title: t('search.restoreConfirmationTitle'),
			content: (<Trans i18nKey="search.restoreConfirmationContent" values={{ name: itemName }}></Trans>), //t('search.restoreConfirmationContent', { itemName }),
			okText: t('search.yes'),
			okType: 'primary',
			cancelText: t('search.no'),
			onOk() {
				handleRestore();
			},
			onCancel() {
				// Do nothing
			},
		});
	};

	const actionHandlerMap = {
		'myDrive': {
			'open': handleFileClick,
			'openFolder': (_selectedRow: any) => {
				console.log(_selectedRow.parent === undefined ? _selectedRow?.folder : _selectedRow?.parent)
				handleOpenFolder(_selectedRow.parent === undefined ? _selectedRow?.folder : _selectedRow?.parent);
				app.setAppState({ ...app.state, searchTerm: null });
			}
		},
		'trash': {
			'delete': async (_selectedRow: any) => {
				await deleteConfirmation();
			},
			'revert': async (_selectedRow: any) => {
				await restoreConfirmation();
			}
		},
		'googleDrive': {
			'open': (_selectedRow: any) => { },
			'openFolder': (_selectedRow: any) => {
				// route to google drive folder
				app.setAppState({ ...app.state, searchTerm: null });
				app.state.setSelectedKey(`5-googleDrive-${_selectedRow?.email}`);
				navigate('/google-drive',
					{
						state: {
							selectedDrive: DriveType.GOOGLE_DRIVE,
							googleDrive:
							{
								type: DriveType.GOOGLE_DRIVE,
								email: _selectedRow?.email,
								folderId: _selectedRow?.parents?.[0] || (_selectedRow?.shared ? null : 'root'),
								shared: _selectedRow?.shared,
							}
						}
					}
				);
			}
		}
	}
	const totalResults =
		myDriveData?.myDrive.files.length +
		myDriveData?.myDrive.folders.length +
		myDriveData?.trash.files.length +
		myDriveData?.trash.folders.length +
		googleDriveFiles?.length;

	const rowSelectionMyDrive: any = {
		// type: 'radio',
		selectedRowKeys: selectedMyDriveRow ? [selectedMyDriveRow.id] : [],
		onChange: (selectedRowKeys: any, selectedRows: any) => {
			if (!selectedRowKeys?.length) {
				setSelectedMyDriveRow(null);
				return;
			}
			else if (selectedRowKeys.length > 1) {
				selectedRowKeys = [selectedRowKeys[selectedRowKeys.length - 1]];
				selectedRows = [selectedRows[selectedRows.length - 1]];
			}
			setSelectedMyDriveRow(selectedRows[0]);
		},
	};

	const rowSelectionTrash: any = {
		// type: 'radio',
		selectedRowKeys: selectedTrashRow ? [selectedTrashRow.id] : [],
		onChange: (selectedRowKeys: any, selectedRows: any) => {
			if (!selectedRowKeys?.length) {
				setSelectedTrashRow(selectedRows[0]);
				return;
			}
			else if (selectedRowKeys.length > 1) {
				selectedRowKeys = [selectedRowKeys[selectedRowKeys.length - 1]];
				selectedRows = [selectedRows[selectedRows.length - 1]];
			}
			setSelectedTrashRow(selectedRows[0]);
		},
	};

	const rowSelectionGoogleDrive: any = {
		// type: 'radio',
		selectedRowKeys: selectedGoogleDriveRow ? [selectedGoogleDriveRow?.id] : [],
		onChange: (selectedRowKeys: any, selectedRows: any) => {
			if (!selectedRowKeys?.length) {
				setSelectedGoogleDriveRow(selectedRows[0]);

				return;
			}
			else if (selectedRowKeys.length > 1) {
				selectedRowKeys = [selectedRowKeys[selectedRowKeys.length - 1]];
				selectedRows = [selectedRows[selectedRows.length - 1]];
			}
			setSelectedGoogleDriveRow(selectedRows[0]);
		},
	};

	return (
		<div>
			<Text strong style={{ fontSize: 16 }}>
				{t('search.yourSearchFor')} '{query}' {t('search.returned')} {totalResults} {t('search.results')}.
			</Text>


			<Collapse defaultActiveKey={totalResults > 0 ? ['myDrive', 'trash', 'googleDrive'] : []} style={{ marginTop: 16, borderColor: 'rgba(158, 150, 150, 0)' }}>

				<Panel
					header={<span style={{ fontWeight: 'bold', color: '#595959' }}>{t('search.myDrive')}{' '} <Text style={{
						fontSize: 10,
						color: '#595959'
					}}>
						({myDriveData?.myDrive?.files?.length + myDriveData?.myDrive?.folders?.length})
					</Text>
					</span>}
					key="myDrive"
					style={{ backgroundColor: '#f0f0f0', borderRadius: 4, marginBottom: 8 }}
				>
					{selectedMyDriveRow ? (<ActionBar actionBarType={ACTION_BAR_TYPES.myDrive}
						actionHandlers={[
							() => {
								actionHandlerMap.myDrive.open(selectedMyDriveRow);
							},
							() => {
								actionHandlerMap.myDrive.openFolder(selectedMyDriveRow);
							}
						]}
					/>) : null}
					<Loader isLoading={myDriveLoading} children={null} />
					<Table
						columns={myDriveColumns}
						dataSource={[
							...(myDriveData?.myDrive?.files || [])?.map((file: any) => ({ ...file, isFolder: false, handleClick: handleFileClick })),
							...(myDriveData?.myDrive?.folders || [])?.map((folder: any) => ({ ...folder, isFolder: true })),
						]}
						rowKey={(record) => record.id}
						pagination={false}
						style={{ marginTop: 8 }}
						rowSelection={rowSelectionMyDrive}
					/>
				</Panel>

				<Panel
					header={<span style={{ fontWeight: 'bold', color: '#595959' }}>{t('search.trash')}{' '} <Text style={{
						fontSize: 10,
						color: '#595959'
					}}>
						({myDriveData?.trash?.files?.length + myDriveData?.trash?.folders?.length})
					</Text>
					</span>}
					key="trash"
					style={{ backgroundColor: '#f0f0f0', borderRadius: 4, marginBottom: 8 }}
				>
					{selectedTrashRow ? (<ActionBar actionBarType={ACTION_BAR_TYPES.trash}
						actionHandlers={[
							() => {
								actionHandlerMap.trash.revert(selectedTrashRow);
							},
							() => {
								actionHandlerMap.trash.delete(selectedTrashRow);
							}
						]}
					/>) : null}
					<Loader isLoading={restoreFolderLoading || restoreFileLoading || deleteItemLoading} children={null} />
					<Table
						columns={myDriveColumns}
						dataSource={[
							...(myDriveData?.trash?.files || []).map((file: any) => ({ ...file, isFolder: false, })),
							...(myDriveData?.trash?.folders || []).map((folder: any) => ({ ...folder, isFolder: true })),
						]}
						rowKey={(record) => record.id}
						pagination={false}
						style={{ marginTop: 8 }}
						rowSelection={rowSelectionTrash}
					/>
				</Panel>

				{
					(googleDriveData && Object.keys(googleDriveData).length > 0) ? (
						Object.keys(googleDriveData).map((account: any) => {
							const accountData = googleDriveData[account];
							return (
								<Panel
									header={<span style={{ fontWeight: 'bold', color: '#595959' }}> {t('search.googleDrive')} ({account}){' '} <Text style={{
										fontSize: 10,
										color: '#595959'
									}}>({accountData?.files?.length || 0})</Text> </span>}
									key={account}
									style={{ backgroundColor: '#f0f0f0', borderRadius: 4, marginBottom: 8 }}
								>
									{selectedGoogleDriveRow ? (<ActionBar actionBarType={ACTION_BAR_TYPES.googleDrive}
										actionHandlers={[
											() => {
												actionHandlerMap.googleDrive.openFolder(selectedGoogleDriveRow);
											}
										]}
									/>) : null}

									<Loader isLoading={googleDriveLoading} children={null} />
									<Table
										columns={googleDriveColumns}
										dataSource={accountData?.files?.map((file: any) => ({ ...file, isFolder: file?.mimeType?.includes('folder'), email: account, parents: file?.parents }))}
										rowKey={(record) => record.id}
										pagination={false}
										style={{ marginTop: 8 }}
										rowSelection={rowSelectionGoogleDrive}
									/>
								</Panel>
							);
						})
					) : null
				}
			</Collapse>
		</div>
	);
};

export default SearchResults;
