// // empty react component

import { useState, useContext, useLayoutEffect, Suspense, useRef, useEffect } from 'react';
import { Button, Modal, Space } from 'antd';
import { createDirectoryList } from '../utils/common/helpers';
import {
  ArrowLeftOutlined,
  DeleteFilled
} from '@ant-design/icons';
import { Loader } from '../components/loader';
import BreadCrumbs from '../components/dashboard/breadCrumbs';
import FileList from '../components/trashFolder/fileList';
import { useTrashFilesList } from '../react-query/queries/fetchTrashFiles';
import { queryClient } from '../react-query/reactQueryClient';
import { useCleanTrash } from '../react-query/mutations/deleteTrash';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Trash = () => {
  const { t } = useTranslation();
  // use location state to get the folder id
  const location = useLocation();
  const { state } = location;
  const [fileList, setFileList] = useState<Array<any>>([]);
  const [breadcrumbs, setBreadcrumbs] = useState<any[]>([]);
  const [selectedFolderId, setSelectedFolderId] = useState(state?.folderId || null);
  const { data: fileListFetched, isLoading: isFileListLoading, isError: isFileListError, refetch } = useTrashFilesList(selectedFolderId);
  const [isLoading, setIsLoading] = useState(false);
  const { mutateAsync: cleanTrash, isLoading: cleanTrashLoading } = useCleanTrash();
  const [selectedFolder, setSelectedFolder] = useState<any>(null);

  useEffect(() => {
    setIsLoading(true);
    refetch().then(() => {
      setIsLoading(false);
    }
    );
  }, []);

  const onUpdate = async () => {
    setIsLoading(true);
    await queryClient.invalidateQueries(["trashFileList", selectedFolderId]);
    setIsLoading(false);

  }

  useLayoutEffect(() => {
    const structured = createDirectoryList(fileListFetched);
    setFileList(structured || []);
  }, [fileListFetched]);

  const handleFolderClick = (record: any) => {
    if (record.type === 'folder') {

      // in case if the folder is missing the lastSystemPathFolderNames
      if (!record.lastSystemPathFolderNames && record.parent) {
        if (selectedFolder?.lastSystemPathFolderNames) {
          if (selectedFolder?.lastSystemPathFolderNames.endsWith('/')) {
            record.lastSystemPathFolderNames = selectedFolder?.lastSystemPathFolderNames + '/' + record.name
          }
          else {
            record.lastSystemPathFolderNames = selectedFolder?.lastSystemPathFolderNames + '/' + record.name
          }
        }
        else {
          record.lastSystemPathFolderNames = '/' + selectedFolder.name + '/' + record.name;
        }
      }
      setSelectedFolder(record);

      setBreadcrumbs((prev) => [...prev, record]);
      setSelectedFolderId(record.id);
    }
  };

  const handleBackClick = () => {
    if (breadcrumbs.length === 0) {
      // Already at root, do nothing or handle as needed
    } else if (breadcrumbs.length === 1) {
      // If we're going back to the root, we should set the fileList to the original top-level data
      setBreadcrumbs([]);
      setSelectedFolderId(null);
      setSelectedFolder(null);
    } else if (breadcrumbs?.length > 1) {
      // If we're navigating back in a subdirectory, handle as before
      const newBreadcrumbs = breadcrumbs.slice(0, -1);
      setBreadcrumbs(newBreadcrumbs);
      setSelectedFolderId(newBreadcrumbs[newBreadcrumbs.length - 1].id);
      setSelectedFolder(newBreadcrumbs[newBreadcrumbs.length - 1]);
    }
  };
  const uniqueTableKey = breadcrumbs.map((crumb) => crumb.name).join('/');
  const deleteConfirmation = async () => {
    Modal.confirm({
      title: t('trash.clearTrashConfirmationTitle'),
      content: t('trash.clearTrashConfirmationContent'),
      okText: t('trash.yes'),
      okType: 'danger',
      cancelText: t('trash.no'),
      onOk() {
        onDelete()
      },
      onCancel() {
        // Do nothing
      },
    });
  };

  const onDelete = async () => {
    try {
      await cleanTrash();
      await onUpdate();

    } catch (error) {

    }
  }

  const handleBreadcrumbClick = (index: number) => {
    if (index === -1) {
      setBreadcrumbs([]);
      setSelectedFolderId(null);
      return;
    }
    const newBreadcrumbs = breadcrumbs.slice(0, index);
    setBreadcrumbs(newBreadcrumbs);
    setSelectedFolderId(newBreadcrumbs[newBreadcrumbs.length - 1].id);
  }
  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        {/* Adjusted for space-between */}
        <Loader isLoading={isFileListLoading || isLoading || cleanTrashLoading} children={null} />
        <Space style={{ marginBottom: 16 }}>
          <Button onClick={handleBackClick} icon={<ArrowLeftOutlined rev={undefined} />}>
            {t('trash.back')}
          </Button>

        </Space>
        <div style={{ flexGrow: 1, display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            onClick={async (e: any) => { await deleteConfirmation() }}
            icon={<DeleteFilled rev={undefined} />}
            style={{ backgroundColor: '#E43D4F', color: 'white' }}
            disabled={fileList.length === 0}
          >
            {t('trash.clearTrash')}
          </Button>
        </div>
      </div>
      <BreadCrumbs breadcrumbs={breadcrumbs} title='Trash' onClick={handleBreadcrumbClick} />
      <FileList
        onUpdate={onUpdate}
        key={uniqueTableKey}
        fileList={fileList}
        handleFolderClick={handleFolderClick}
        currentFolderId={selectedFolderId}
        currentFolder={selectedFolder}
      />
    </div>
  );
};

export default Trash;
