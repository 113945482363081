
import { useQuery } from "react-query";
import axiosClient from '../axiosInstance';
import { notification } from "antd";

export function useGetUser() {
	const query = useQuery(
		["getUser"],
		async () => {
			const url = `file/user`;
			const result = await axiosClient.get(url).then((r) => r.data);
			return result.data;

		},
		{
			enabled: true,
			refetchOnMount: false,
			refetchOnWindowFocus: false,
			onError: (error: any) => {
				// show notification
				console.log("error", error);
				notification.error({
					message: 'Error',
					description: error?.message || "Something went wrong",
				});
			},
		}
	);

	return query;
}
