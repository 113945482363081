import React, { useCallback, useEffect, useState } from 'react';
import { Card, Button, Typography, Menu, Dropdown } from 'antd';
import { useContext } from 'react';
import { AppContext } from '../components/AppContext';
import { useNavigate } from 'react-router-dom';
import { getTokenDesktop } from '../react-query/queries/loginUser';
import { useTranslation } from 'react-i18next';
import { GlobalOutlined } from '@ant-design/icons';
import '../styles/login.css';
import Link from 'antd/es/typography/Link';


const LoginScreen = () => {
  const { app } = useContext<any>(AppContext);
  const { t, i18n } = useTranslation();

  const [isProcessing, setIsProcessing] = useState(false);
  const navigate = useNavigate();
  const [initialized, setInitialized] = useState(false);

  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
    console.log('Language changed to:', lng, i18n);
  };

  useEffect(() => {
    app.checkLoginStatus().then((isLoggedin: boolean) => {
      if (isLoggedin) {
        navigate('/home');
      }
    });
  }, []);
  const openExternalLink = (url: string) => {
    //  open with electron
    // @ts-ignore
    if (window.electron) {
      // @ts-ignore
      window.electron.openExternalLink(url);
    } else {
      // @ts-ignore
      window?.open?.(url, '_blank');
    }
  }

  const handleLoginClick = () => {
    // @ts-ignore
    window.electron.openLoginWindow(process.env.REACT_APP_SSO_LOGIN_URL);
  };
  const handleLoginCode = useCallback(async (code: string) => {
    if (isProcessing) return; // Prevent multiple calls
    setIsProcessing(true);

    try {
      const resp = await getTokenDesktop(code);
      if (resp && resp?.data?.data) {
        const token = resp?.data.data.id_token;
        const refreshToken = resp?.data.data.refresh_token;

        // @ts-ignore
        await window.electron.setStoreValue('token', token);
        // @ts-ignore
        await window.electron.setStoreValue('refresh_token', refreshToken);
        // Set cookie (this is for later to extend on cookies setup)
        const cookie = {
          url: 'http://localhost', // Your domain
          name: 'file_manager_signed_token',
          value: token,
          expirationDate: Math.floor(Date.now() / 1000) + 3600, // 1 hour from now
        };
        // @ts-ignore
        await window.electron.setCookie(cookie);

        // app.setAppState({ isLoggedin: true });
        navigate('/home');
      }
    } catch (error) {
      console.error('Error handling login code:', error);
    } finally {
      setIsProcessing(false); // Reset the processing flag
    }
  }, [isProcessing, app, navigate]);

  useEffect(() => {
    if (!initialized) {
      // @ts-ignore
      window.electron.onLoginCode(handleLoginCode);
      setInitialized(true); // Set initialized flag to true

      // Cleanup the effect
      return () => {
        // @ts-ignore
        window.electron.removeLoginCodeListener(handleLoginCode);
      };
    }
  }, []);

  const menu = (
    <Menu>
      <Menu.Item key="kor" onClick={() => changeLanguage('kor')}
        style={i18n.language === 'kor' ? { fontWeight: 'bold', backgroundColor: '#E43D4F', color: 'white' } : {}}
      >
        KOR
      </Menu.Item>

      <Menu.Item key="en" onClick={() => changeLanguage('en')}
        style={i18n.language === 'en' ? { fontWeight: 'bold', backgroundColor: '#E43D4F', color: 'white' } : {}}
      >
        ENG
      </Menu.Item>
    </Menu>
  );


  return (
    <div className="login-container">
      <Card className="login-card">
        <div style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
          <img src="logo192.png" alt="CADian Portal" style={{
            // width: '2',
            height: '4rem',
            borderRadius: '50%',
            margin: '0.5rem',
          }} />
          <Typography style={{ fontWeight: 'bold', fontFamily: 'sans-serif', fontSmooth: 'always', fontSize: '1.7rem', marginLeft: '0.1rem' }}>
            {t('login.welcome')}
          </Typography>
        </div>
        <div style={{ display: 'flex', direction: 'inherit', justifyContent: 'center', alignItems: 'center', marginBottom: '8vh' }}>
          <Typography style={{ fontFamily: 'sans-serif', fontSmooth: 'always', color: 'GrayText' }}>{t('login.signInToUse')}</Typography>
          {/* <Typography style={{ fontWeight: 'bold', fontFamily: 'sans-serif', fontSmooth: 'always', marginLeft: '0.3rem', color: 'GrayText' }}> CADian.</Typography> */}
        </div>
        <Button
          onClick={handleLoginClick}
          type='primary'
          style={{ fontWeight: 'bold', fontFamily: 'sans-serif', backgroundColor: '#E43D4F', minWidth: '17vw', minHeight: '6vh', fontSize: '1rem', borderRadius: '3rem', marginBottom: '1rem' }}>
          {t('login.signIn')}
        </Button>
        <Typography style={{ color: 'InactiveCaptionText' }}> {t('login.redirectMessage')}</Typography>
        <div style={{ marginTop: '100px' }}>
          <div style={{ display: 'flex', direction: 'inherit', justifyContent: 'center', alignItems: 'center' }}>
            <Typography style={{ fontFamily: 'sans-serif', fontSmooth: 'always', color: 'GrayText' }}>{t('login.newToCADian')}</Typography>
            <Typography style={{ fontWeight: 'bold', fontFamily: 'sans-serif', fontSmooth: 'always', marginLeft: '0.3rem' }}><Link onClick={() => { openExternalLink('https://dev-accounts.cadian.com/register/email') }}> {t('login.createAccount')}</Link></Typography>
          </div>
          <Dropdown overlay={menu} trigger={['click']}>
            <Button style={{
              backgroundColor: 'transparent',
              border: 'none',
              boxShadow: 'none',
              color: 'black',
              padding: '0',
              // width: '50px',
            }} icon={<GlobalOutlined style={{
              // fontSize: '20px',
            }} />} />
          </Dropdown>

        </div>
      </Card>
    </div>
  );
};

export default LoginScreen;
