import React from 'react';
import { Spin } from 'antd';

export const Loader = ({ isLoading, children }: { isLoading: Boolean; children: any }) => {
  const loaderStyle = {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    // transform: 'translate(-50%, -50%)',
    zIndex: 1000,
    justifyContent: 'center',
    alignItems: 'center',
    display: isLoading ? 'flex' : 'none',
  };

  return (
    <div style={{ position: 'relative' }}>
      <div style={{ ...loaderStyle, position: 'fixed' }}>
        <Spin size="large" />
      </div>
      {children}
    </div>
  );
};
