import { Button, Modal, Space, notification, Input, Form, InputNumber, message } from "antd";
import Table, { ColumnType } from "antd/es/table";
import { SortOrder } from "antd/es/table/interface";
import moment from "moment";
import { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from "react";
import {
  FolderOutlined,
  FileOutlined,
  CheckCircleFilled,
  CloseCircleFilled,
} from '@ant-design/icons';

import { randomUUID } from '../../utils/common/helpers';
import { useCreateFolder } from '../../react-query/mutations/postCreateFolder';
import { queryClient } from "../../react-query/reactQueryClient";
import ActionBar from "./tableActionBar"
import { useDeleteItem } from '../../react-query/mutations/delete';
import { Loader } from "../loader";
import DownloadNotification, { DownloadItem } from "./downloadNotification";
import { useDropzone } from 'react-dropzone';
import { useRenameItem } from '../../react-query/mutations/rename';
import FolderNavigation from "../../components/dashboard/folderNavigation";
import { useMoveItem } from '../../react-query/mutations/moveFolder';
import { useCopyItem } from '../../react-query/mutations/copyItem';
import { useTranslation, Trans } from 'react-i18next';

const FileList = forwardRef((props: any, ref: any) => {

  const inputRef = useRef(null); // Create a ref for the input element
  const { t, i18n } = useTranslation();
  const { fileList, handleFolderClick, handleFileClick, currentFolderId, onUpdate, onUpload, completedJobs } = props;
  const [data, setData] = useState<any>(fileList || []);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedAction, setSelectedAction] = useState(''); // Add this state to store the selected action
  const { mutateAsync: deleteFolder, isLoading: deleteFolderLoading } = useDeleteItem('folder', currentFolderId);
  const { mutateAsync: deleteFile, isLoading: deleteFileLoading } = useDeleteItem('file', currentFolderId);
  const { mutateAsync: renameFolder, isLoading: renameFolderLoading } = useRenameItem('folder', currentFolderId);
  const { mutateAsync: renameFile, isLoading: renameFileLoading } = useRenameItem('file', currentFolderId);
  const { mutateAsync: createFolder, isLoading: folderCreationLoading } = useCreateFolder();
  const [downloads, setDownloads] = useState<DownloadItem[]>([]);
  const [editingKey, setEditingKey] = useState('');
  const [newFolderName, setNewFolderName] = useState('');
  const [selectOnMove, setSelectOnMove] = useState(false);
  const { mutateAsync: moveFolder, isLoading: moveFolderLoading } = useMoveItem('folder', currentFolderId);
  const { mutateAsync: moveFile, isLoading: moveFileLoading } = useMoveItem('file', currentFolderId);
  const { mutateAsync: copyFolder, isLoading: copyFolderLoading } = useCopyItem('folder', currentFolderId);
  const { mutateAsync: copyFile, isLoading: copyFileLoading } = useCopyItem('file', currentFolderId);

  const onDrop = useCallback((acceptedFiles: any) => {
    onUpload(acceptedFiles[0]);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, noClick: true });

  useEffect(() => {
    if (selectedRowKeys.length === 0) {
      setSelectedAction('');
      setEditingKey('');
      setNewFolderName('');
    }
  }, [selectedRowKeys]);

  const isConvertedFileType = (name: string) => {
    return name?.endsWith('.dwg') ||
      name?.endsWith('.dxf') ||
      name?.endsWith('.ifc') ||
      name?.endsWith('..rvt') ||
      name?.endsWith('.dgn');
  }

  const rowSelection = {
    selectedRowKeys,
    onChange: (newSelectedRowKeys: any) => {
      // disable if already editing
      if (editingKey && newSelectedRowKeys?.length) return;
      // disable if the selected item is a file and is not present in the completed jobs
      if (newSelectedRowKeys.length === 1) {
        const item = data.find((item: any) => item.key === newSelectedRowKeys[0]);
        const currentFileJob = completedJobs.find((job: any) => job.id === item.key);
        if (item?.type === 'file' && isConvertedFileType(item?.name) && ['processing', 'waiting'].includes(currentFileJob?.status)) {
          setSelectedRowKeys([]);
          notification.error({ message: t('dashboard.fileList.fileProcessingError'), duration: 2 })
          return;
        }
      }
      if (newSelectedRowKeys?.length > 1) {
        setSelectedRowKeys(newSelectedRowKeys.slice(-1));
      }
      else if (newSelectedRowKeys.length === 0) {
        setSelectedRowKeys(newSelectedRowKeys);
        // cancel the editing if the selected row is empty
        setEditingKey('');
        setNewFolderName('');
        setSelectedAction('');
        // set the record to not editable
        const newData = [...data];
        newData.forEach(item => {
          item.editable = false;
        });
        // filter out the temporary folder
        const filteredData = newData.filter((item: any) => item.custom !== true);
        setData(filteredData)
        // cancle any move or copy action
        setSelectOnMove(false);


      }
      else {
        setSelectedRowKeys(newSelectedRowKeys);
      }
    },
  };

  const edit = (record: any) => {
    setEditingKey(record.key);
  };

  const cancel = () => {
    const item = data.find((item: any) => item.key === editingKey)
    if (!item) return;
    if (!item.custom) {
      const newData = [...data];
      const item = newData.find(item => selectedRowKeys[0] === item.key);

      item.editable = false;
      setData(newData);

    }
    else if (item.custom === true) {
      // Remove the temporary folder if the user cancels the creation
      setData(data.filter((item: any) => item.key !== editingKey));
    }
    setEditingKey('');
    setNewFolderName('');
  };

  const deleteFileConfirmation = (record: any) => {
    const itemName = record.type === 'file' ? 'file' : 'folder';
    Modal.confirm({
      title: t("dashboard.fileList.deleteConfirmation.title"),
      content: (<Trans i18nKey="dashboard.fileList.deleteConfirmation.content" values={{ name: record.name }}></Trans>),
      // t("dashboard.fileList.deleteConfirmation.content", { name: record.name }),
      okText: t("dashboard.fileList.deleteConfirmation.okText"),
      okType: 'danger',
      cancelText: t("dashboard.fileList.deleteConfirmation.cancelText"),
      onOk() {
        onDelete()
      },
      onCancel() {
        // Do nothing
      },
    });
  };

  const onDelete = async () => {
    try {
      if (selectedRowKeys.length === 0) {
        notification.error({ message: 'Please select a folder to delete' })
        return;
      }
      const isFile = data.find((item: any) => item.key === selectedRowKeys[0])?.type === 'file';

      const deleteItem = isFile ? deleteFile : deleteFolder;
      await deleteItem({ itemId: selectedRowKeys[0] });
      // queryClient.invalidateQueries(["fileList", currentFolderId]);
      await onUpdate();
      setSelectedRowKeys([]);

    } catch (error) {

    }
  }


  const startDownload = async (url: any, fileName: any) => {
    const newDownload = {
      id: Math.random().toString(36).substr(2, 9), // simple unique ID generator
      fileName,
      progress: 0, // initial progress
    };
    setDownloads(prev => [...prev, newDownload]);
    // @ts-ignore  
    const token = await window.electron.getStoreValue('token');

    // Fetch the file as a Blob
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/octet-stream',
        'Authorization': `Bearer ${token}`,
      },
    });
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const reader = response?.body?.getReader();
    const contentLength = +(response?.headers?.get?.('Content-Length') || '0') || 0;
    const mimeType = response.headers.get('Content-Type');

    let receivedLength = 0; // received that many bytes at the moment
    let chunks = []; // array of received binary chunks (comprises the body)
    while (true) {
      const { done, value } = await reader?.read() as { done: boolean, value: Uint8Array };

      if (done) {
        break;
      }
      chunks.push(value);
      receivedLength += value.length;

      // Update progress in a way to ensure the UI updates
      const newProgress = (receivedLength / contentLength) * 100;


      setDownloads(currentDownloads => currentDownloads.map(d => {
        if (d.id === newDownload.id && d.progress < 100) {
          return { ...d, progress: newProgress };
        }
        return d;
      }));
      await new Promise(resolve => setTimeout(resolve, 0)); // Force React to acknowledge the state update
    }

    const blob = new Blob(chunks);
    const blobWithCorrectType = new Blob([blob], { type: mimeType || undefined });

    const blobUrl = window.URL.createObjectURL(blobWithCorrectType);

    const link = document.createElement('a');
    link.href = blobUrl;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    setTimeout(() => {
      window.URL.revokeObjectURL(blobUrl); // Clean up
      document.body.removeChild(link);
    }, 100); // Delay slightly to ensure download starts
    setTimeout(() => {
      setDownloads(currentDownloads => currentDownloads.filter(d => {
        return d.id !== newDownload.id
      }));
    }, 5000); // Delay slightly to ensure download starts

    // Optionally hide the notification after a delay
  };

  const downloadFile = async () => {
    const fileName = data.find((item: any) => item.key === selectedRowKeys[0])?.name;
    const downloadUrl = `${process.env.REACT_APP_API_FILE_SERVER_URL}/file/${selectedRowKeys[0]}`

    await startDownload(downloadUrl, fileName);

  };
  const onDownload = async () => {
    // if the selected id is a folder
    if (selectedRowKeys.length === 0) {
      notification.error({ message: 'Please select a file to download' })
      return;
    }

    const isFile = data.find((item: any) => item.key === selectedRowKeys[0])?.type === 'file';
    if (isFile) await downloadFile();

  }

  const save = async (key: any) => {
    const newData = [...data];
    const index = newData.findIndex(item => key === item.key);
    if (!newFolderName?.length && !newData[index]?.custom) {
      notification.error({ message: t('dashboard.fileList.folderNameError') })
      return;
    }
    if (index > -1) {
      const item = newData[index];
      if (item.custom === true) {
        try {

          newData.splice(index, 1);
          setData(newData);
          setEditingKey('');
          const updatedNewFolderName = newFolderName || "New Folder";
          setNewFolderName('');
          await createFolder({ folderId: currentFolderId, folderName: updatedNewFolderName })
          await onUpdate();
        } catch (error) {

        }
      }
      else {
        try {
          const isFile = data.find((item: any) => item.key === selectedRowKeys[0])?.type === 'file';
          const renameItem = isFile ? renameFile : renameFolder;
          let shouldUpdate = true;
          if (isFile) {
            //  add the oroiginal extension to the new folder name
            const originalExtension = data.find((item: any) => item.key === selectedRowKeys[0])?.name.split('.').slice(-1).join('.');
            const updatedNewFolderName = `${newFolderName}.${originalExtension}`;
            // if user presses enter without changing the name
            if (updatedNewFolderName !== newData[index].name) {
              await renameItem({ itemId: selectedRowKeys[0], name: updatedNewFolderName });
            }
            else {
              shouldUpdate = false;
            }

            newData[index].name = updatedNewFolderName;
          }
          else {
            // if user presses enter without changing the name
            if (newFolderName !== newData[index].name) {
              await renameItem({ itemId: selectedRowKeys[0], name: newFolderName });
            }
            else {
              shouldUpdate = false;
            }
            newData[index].name = newFolderName;
          }
          newData[index].editable = false;
          setData(newData);
          setSelectedRowKeys([]);
          setEditingKey('');
          setNewFolderName('');
          // only update if the name is changed
          if (shouldUpdate) {
            await onUpdate();
          }

        } catch (error) {

        }
      }
    }
  };
  useEffect(() => {
    if (editingKey && inputRef?.current) {
      (inputRef.current as any)?.focus(); // Focus the input when a new folder is added
    }
  }, [editingKey]);

  const addFolder = () => {
    if (editingKey) return;
    const newData = {
      key: randomUUID(),
      name: '',
      type: 'folder',
      editable: true,
      custom: true
    };
    setData([newData, ...data]);
    edit(newData);
  };

  const onRename = () => {
    if (editingKey) return;
    // set the editable true for the selected folder
    try {
      const newData = [...data];
      const item = newData.find(item => selectedRowKeys[0] === item.key);
      item.editable = true;
      setData(newData);
      const isFile = data.find((item: any) => item.key === selectedRowKeys[0])?.type === 'file';
      isFile ? setNewFolderName(item.name.split('.').slice(0, -1).join('.')) : setNewFolderName(item.name);;

      selectedRowKeys.length === 1 ? edit(data.find((item: any) => item.key === selectedRowKeys[0])) : notification.error({ message: 'Please select a folder to rename' })

    } catch (error) {

    }

  }

  const onMoveSelection = async (folderId: string | null) => {
    try {
      setSelectOnMove(false);
      if (selectedRowKeys.length === 0) {
        notification.error({ message: 'Please select a folder to copy' })
        return;
      }
      const isFile = data.find((item: any) => item.key === selectedRowKeys[0])?.type === 'file';
      if (selectedAction === 'move') {

        const moveItem = isFile ? moveFile : moveFolder;
        await moveItem({ itemId: selectedRowKeys[0], parentFolderId: folderId });
        message.success(t('dashboard.fileList.moveSuccess', { name: `${isFile ? 'File' : 'Folder'}` }));
        await onUpdate();
      }
    } catch (error) {
      message.error(t('dashboard.fileList.moveError'));
    }
  }

  const onMoveStart = async () => {
    if (selectedRowKeys.length === 0) {
      notification.error({ message: 'Please select a folder to move' })
      return;
    }
    setSelectOnMove(true);
  }

  const onMoveCancel = () => {
    setSelectOnMove(false);
  }
  // create a function getAction call which returns the action on click of the button
  const handleAction = async (action: string) => {
    switch (action) {
      case 'open-file':
        const selectedItemForAction = data.find((item: any) => item.key === selectedRowKeys[0]);
        if (selectedItemForAction.type === 'folder') { handleFolderClick(selectedItemForAction) }
        else { handleFileClick(selectedItemForAction) }

        break;
      case 'delete':
        // onDelete();
        deleteFileConfirmation(data.find((item: any) => item.key === selectedRowKeys[0]));
        break;
      case 'download':
        await onDownload()
        break;
      case 'move':
        await onMoveStart();
        break;
      case 'copy':
        try {
          const isFile = data.find((item: any) => item.key === selectedRowKeys[0])?.type === 'file';
          const copyItem = isFile ? copyFile : copyFolder;
          await copyItem({ itemId: selectedRowKeys[0], parentFolderId: currentFolderId });
          message.success(t('dashboard.fileList.copySuccess', { name: `${isFile ? 'File' : 'Folder'}` }));
          await onUpdate();
          return;
        } catch (error) {
          message.error(t('dashboard.fileList.copyError'));
        }

        break;
      case 'rename':
        onRename()
        break;
      default:
        break;
    }
  }

  useEffect(() => {
    setData(fileList);
  }, [fileList.length, fileList]);
  const findCompletedJobStatus = (id: string) => {
    return completedJobs.find((job: any) => job.id === id)?.status;
  }
  const columns: Array<ColumnType<any>> = [
    {
      title: t('dashboard.fileList.columns.name'),
      showSorterTooltip: false,
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
      render: (text, record) => (
        record.editable === true ? (
          <Space>
            <Input
              ref={inputRef}
              autoFocus
              value={newFolderName}
              placeholder="folder-name"
              onPressEnter={(event) => save(record.key)}
              onChange={(e) => setNewFolderName(e.target.value)}
              style={{ borderColor: '#E43D4F' }}
              onBlur={() => save(record.key)}
              onKeyDown={(event) => {
                if (event.key === 'Escape') {
                  cancel();
                }
              }}
            />
          </Space>
        ) : (
          <Space>
            {record.type === 'folder' ? (
              <FolderOutlined rev={undefined} />
            ) : (
              <FileOutlined rev={undefined} />
            )}
            <Button
              // add tooltip to the button
              title={record.type === "file" && isConvertedFileType(record?.name) && (!findCompletedJobStatus(record.key) || findCompletedJobStatus(record.key) === 'failed') ? t('dashboard.jobProcessFail') : (findCompletedJobStatus(record.key) === 'completed' ? '' : t('dashboard.jobProcessing'))}
              disabled={record.type === "file" && isConvertedFileType(record?.name) && (!findCompletedJobStatus(record.key) || findCompletedJobStatus(record.key) === 'failed' || findCompletedJobStatus(record.key) === 'processing')}
              type="link"
              onClick={() => {
                if (record.type === 'folder') { handleFolderClick(record) }
                else { handleFileClick(record) }
              }
              }
            >
              {text}
            </Button>
          </Space>

        )
      ),
    },
    {
      title: t('dashboard.fileList.columns.lastModified'),
      showSorterTooltip: false,
      dataIndex: 'modifiedDateTime',
      key: 'lastModified',
      defaultSortOrder: 'descend' as SortOrder,
      sorter: (a, b) => {
        if (a.type === 'folder') return 1;
        if (b.type === 'folder') return -1;
        return moment(a.modifiedDateTime).unix() - moment(b.modifiedDateTime).unix();
      },
      render: (text: string) => {
        if (i18n.language === 'en') {
          // return Aug, 12 2021 12:00
          return moment(text)?.local()?.format('MMM DD, YYYY HH:mm')
        }
        else {

          return moment(text)?.local()?.format('YYYY-MM-DD HH:mm')
        }
      },
    },
    {
      title: t('dashboard.fileList.columns.format'),
      showSorterTooltip: false,
      dataIndex: 'mimeType',
      key: 'mimeType',
      sorter: (a, b) => {
        const firstFormat = a.mimeType?.split('/')[1] || '';
        const secondFormat = b.mimeType?.split('/')[1] || '';
        return firstFormat.localeCompare(secondFormat);
      },
      render: (text, record) => {
        if (!text) return '';
        return record?.name?.split ? record?.name?.split('.').slice(-1).join('.') : '';
      }
    },
    {
      title: t('dashboard.fileList.columns.size'),
      showSorterTooltip: false,
      dataIndex: 'size',
      key: 'size',
      sorter: (a, b) => a.size - b.size,
      // change to the appropriate unit notation
      render: (text) => {
        if (!text) return '';

        if (text < 1024) {
          return `${text} B`;
        } else if (text < 1024 * 1024) {
          return `${(text / 1024).toFixed(2)} KB`;
        } else if (text < 1024 * 1024 * 1024) {
          return `${(text / (1024 * 1024)).toFixed(2)} MB`;
        } else {
          return `${(text / (1024 * 1024 * 1024)).toFixed(2)} GB`;
        }
      },

    }
  ];
  useImperativeHandle(ref, () => ({
    addFolder: addFolder,
  }));

  const getDisabledActions = (): any => {
    if (!selectedRowKeys || selectedRowKeys.length === 0) {
      return ['delete', 'download', 'move', 'copy', 'rename', 'open-file'];
    }
    const selectedRecord = data.find((item: any) => item.key === selectedRowKeys[0]);
    if (!selectedRecord) return ['delete', 'download', 'move', 'copy', 'rename', 'open-file'];

    if (selectedRecord?.type === 'folder') {
      return ['open-file']
    }

    const isopenFileDisabled = selectedRecord?.type === "file" && isConvertedFileType(selectedRecord?.name) && (!findCompletedJobStatus(selectedRecord?.key) || findCompletedJobStatus(selectedRecord?.key) === 'failed' || findCompletedJobStatus(selectedRecord.key) === 'processing')
    if (isopenFileDisabled) {
      return ['open-file']
    }
    return []
  }
  return (
    <div {...getRootProps()}>
      <input {...getInputProps()} />
      {isDragActive && (
        <div style={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgba(0,0,0,0.5)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          color: 'white',
          fontSize: '2em',
          zIndex: 9999
        }}>
          Drop here
        </div>
      )}
      {selectedRowKeys.length === 1 &&
        <ActionBar
          selectedCount={selectedRowKeys.length}
          setSelectedAction={setSelectedAction}
          selectedAction={selectedAction}
          performAction={handleAction}
          disabledActions={getDisabledActions()}
        />
      }
      <Loader isLoading={deleteFolderLoading || deleteFileLoading || folderCreationLoading || renameFolderLoading || renameFileLoading || moveFolderLoading || moveFileLoading || copyFolderLoading || copyFileLoading} children={null} />
      <FolderNavigation isVisible={selectOnMove}
        onCancel={onMoveCancel}
        onMove={onMoveSelection}
        itemName={selectedRowKeys.map((key: any) => data.find((item: any) => item.key === key)?.name)}
        itemid={selectedRowKeys.map((key: any) => data.find((item: any) => item.key === key)?.key)}
        onAddNewFolder={addFolder}
        actionName={selectedAction}
      />
      <Table dataSource={data} columns={columns} rowSelection={rowSelection} pagination={{
        pageSize: 10,
        position: ['bottomCenter'],
      }} />
      <DownloadNotification downloads={downloads} />

    </div>)
});

export default FileList;