import React from 'react';
import axios from 'axios';

export const AppContext = React.createContext({});

interface IAppContextProp {
  children: React.ReactElement;
}

export interface IAppContextState {
  user?: any | null;
  email?: string | null;
  defaultLangKey?: string | null;
  isLoggedin?: boolean;
  isLoading?: boolean;
  googleDrive?: any;
  platformIsWeb?: boolean;
}

export class AppContextProvider extends React.Component<IAppContextProp, IAppContextState> {
  constructor(props: any) {
    super(props);
    this.state = {
      user: null,
      email: null,
      defaultLangKey: null,
      isLoggedin: false,
      isLoading: false,
      googleDrive: null,
      platformIsWeb: process.env.REACT_APP_PLATFORM === 'web',
    };
    axios.defaults.withCredentials = true;
  }
  // change app state
  public setAppState = (state: any) => {
    this.setState((prevState) => {
      return { ...prevState, ...state }
    });
  };

  // check login status and return boolean
  public checkLoginStatus = async () => {
    if (this.state.platformIsWeb) {
      const token = localStorage.getItem('token');
      return !!token;
    }
    // @ts-ignore
    const token = await window.electron.getStoreValue('token');
    return !!token;
  };

  public logout = async () => {
    if (this.state.platformIsWeb) {
      // remove token
      localStorage.removeItem('token');
      // remove cookie 
      document.cookie = 'file_manager_signed_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
      window.location.href = window.location.pathname + '#/login';

      return;
    }
    // Remove the user's token and other session data
    // @ts-ignore
    await window.electron.removeCookie('file_manager_signed_token');
    // @ts-ignore
    await window.electron.removeStoreValue('token');
    // @ts-ignore
    await window.electron.removeStoreValue('refresh_token');
    window.location.href = window.location.pathname + '#/login';
  }
  componentDidMount(): void {
    this.init();
  }

  //init
  private init = () => {

  };
  render() {
    return <AppContext.Provider value={{ app: this }}>{this.props.children}</AppContext.Provider>;
  }
}
export interface IAppContextValue {
  app: AppContextProvider;
}

export const AppContextType = React.createContext<IAppContextValue | undefined>(undefined);