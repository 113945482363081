// call backend for google drive token
import { useMutation, useQueryClient } from "react-query";
import axiosClient from "../axiosInstance";
import { notification } from "antd";

export function useGoogleAuthLink() {
	const queryClient = useQueryClient();
	return useMutation(
		() => {
			return axiosClient
				.get("google-drive/auth-url")
				.then((res) => {
					return res.data;
				})
				.catch((error) => {
					console.log("error", error);
					notification.error({
						message: "Error",
						description: error?.message || "Something went wrong",
					});
				});
		},
		{
			onSuccess: () => {
				queryClient.invalidateQueries();
			},
		}
	);
}

export function useGoogleAuthCallback() {
	const queryClient = useQueryClient();
	return useMutation(
		({ code }: { code: string }) => {
			return axiosClient
				.get(`google-drive/auth-token?code=${code}`)
				.then((res) => {
					return res.data;
				})
				.catch((error) => {
					console.log("error +> ", error);
					if (error?.response?.status
						&& error?.response?.status === 401) {
						notification.error({
							message: "Error",
							description: error?.message || "Something went wrong",
						});
					}
				});
		},
		{
			onError: (error) => { },
			onSuccess: () => {
				queryClient.invalidateQueries(["getGooGleDriveUser"]);
				// queryClient.invalidateQueries();
			},
		}
	);
}

export function useDeleteGoogleAccount() {
	const queryClient = useQueryClient();
	return useMutation((email: string) => {
		return axiosClient
			.delete(`google-drive/google-auth?email=${email}`)
			.then((res) => {
				return res.data;
			})
			.catch((error) => {
				console.log("error +> ", error);
				if (error?.response?.status
					&& error?.response?.status === 401) {
					notification.error({
						message: "Error",
						description: error?.message || "Something went wrong",
					});
				}
			});
	},
		{
			onError: (error) => { },
			onSuccess: () => {
				queryClient.invalidateQueries();
			},
		}
	);
}