// react-query mutation to call /create-folder 

import { useMutation, useQueryClient } from "react-query";
import axiosInstance from "../axiosInstance";
import { notification } from "antd";


export function useDeleteItem(type: string, _folderId: string | null) {
	const queryClient = useQueryClient();
	const folderId = _folderId;
	const mutation = useMutation({
		mutationKey: ["deleteItem", folderId],
		mutationFn: async ({ itemId }: { itemId: string }) => {
			const url = type === 'folder' ? `file/delete-folder/${itemId}` : `file/delete-file/${itemId}`;

			const folder = await axiosInstance
				.delete(url)
				.then((r) => r.data);
			return folder;
		},
		onSuccess: () => {
		},
		onError: (error: any) => {
			// show notification
			notification.error({
				message: 'Error',
				description: error?.message || "Something went wrong",
			});
		},

	});

	return mutation;
}
