import React, { useEffect, useLayoutEffect, useState, useContext } from 'react';
import { Layout, Menu, Progress, theme, Button } from 'antd';
import { Resizable } from 're-resizable';
import {
  HomeOutlined,
  FileOutlined,
  DeleteOutlined,
  CloudOutlined,
  AppstoreOutlined,
  QuestionCircleOutlined,
  ReloadOutlined,
  KeyOutlined,
  VerifiedOutlined,
  BulbOutlined,
  QuestionOutlined,
  PlaySquareOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  CloudTwoTone,
  FolderOpenOutlined,
  PlayCircleOutlined,
  PlusOutlined,
  Loading3QuartersOutlined,
  GoogleOutlined,
} from '@ant-design/icons';
import Typography from 'antd/es/typography/Typography';
import { useGetUser } from '../react-query/queries/getUser';
import { bytesToGigabytes, getPercentage } from '../utils/common/helpers';
import { useNavigate } from 'react-router-dom';
import { SharedFilesIcon, MyDriveIcon, PlayIcon, PopOutIcon } from './icons/icons';
import { useGetGoogleDriveUserLazyLoad, useGoogleDriveGetUser } from '../react-query/queries/googleDrive';
import { DriveType } from '../components/ExternalDrives/types';
import { AppContext, IAppContextValue } from '../components/AppContext';
import { useTranslation } from 'react-i18next';


const { SubMenu } = Menu;
const { Sider } = Layout;

const ORIGINAL_WIDTH_SIDEBAR = 250;
const COLLAPSED_WIDTH_SIDEBAR = 100;

const Sidebar = () => {
  const { app } = useContext<any>(AppContext);
  const { token: { borderRadiusLG } } = theme.useToken();
  const [collapsed, setCollapsed] = useState(false);
  const [width, setWidth] = useState(ORIGINAL_WIDTH_SIDEBAR);  // Initial width of the sidebar
  const { data: user, isLoading, refetch: fetchUserData } = useGetUser();
  const [selectedKey, setSelectedKey] = useState('1');
  const navigate = useNavigate();
  const { t } = useTranslation();

  // const [currentUser, setCurrentUser] = useState<any>(null);
  // const [googleDriveUser, setGoogleDriveUser] = useState<any>(null);

  if (!app?.state?.selectedKey) {
    app.setAppState({ ...app.state, selectedKey, setSelectedKey });
  }
  const {
    data: fetchGoogleDriveUser,
    isLoading: isGoogleDriveUserLoading,
    refetch: fetchGoogleDriveUserData,
  } = useGoogleDriveGetUser(); // Pass the setter function here

  const toggleCollapsed = () => {
    collapsed ? setWidth(ORIGINAL_WIDTH_SIDEBAR) : setWidth(COLLAPSED_WIDTH_SIDEBAR);
    setCollapsed(!collapsed);
  };
  const [googleDriveSider, setGoogleDriveSider] = useState<any>(null);
  const [currentUserSize, setCurrentUserSize] = useState(app?.state?.user?.currentUsedSize || 0);
  const [maxUserSize, setMaxUserSize] = useState(app?.state?.user?.maxAllowedSize || 0);

  const refetchUserAndUpdateState = async () => {
    const data = await fetchUserData();
    app.setAppState({ ...app.state, user: data.data });
  }
  useEffect(() => {
    fetchUserData().then((delta: any) => {
      // setCurrentUser(delta.data);
      setCurrentUserSize(delta?.data?.currentUsedSize || 0);
      setMaxUserSize(delta?.data?.maxAllowedSize || 0);
      app.setAppState({ ...app.state, user: delta.data, setSelectedKey, selectedKey, refetchUserAndUpdateState });
    });
  }, []);

  useEffect(() => {
    app.setAppState({ ...app.state, isLoading: isLoading || isGoogleDriveUserLoading, setSelectedKey, selectedKey });
  }, [isLoading, isGoogleDriveUserLoading]);

  useLayoutEffect(() => {
    if (app?.state?.googleDrive?.emails?.length > 0) {
      // check if app.state.googleDrive.emails is not same as fetchGoogleDriveUser then update the state
      if (fetchGoogleDriveUser?.length && app.state?.googleDrive?.emails?.length !== fetchGoogleDriveUser?.length) {

        app.setAppState({
          ...app.state, googleDrive: {
            emails: [...fetchGoogleDriveUser],
            refresh: fetchGoogleDriveUserData
          }
        });
      }
    }
    if (!app.state?.googleDrive?.emails?.length) {
      if (fetchGoogleDriveUser) {
        app.setAppState({
          ...app.state, googleDrive: {
            emails: fetchGoogleDriveUser,
            refresh: fetchGoogleDriveUserData,
          },
          setSelectedKey, selectedKey
        });
      }
      else {
        fetchGoogleDriveUserData().then((data) => {
          app.setAppState({
            ...app.state, googleDrive: {
              emails: data.data,
              refresh: fetchGoogleDriveUserData
            },
            setSelectedKey, selectedKey
          });
        });
      }
    }
  }, [fetchGoogleDriveUser]);

  const currentUrl = window.location.pathname;

  useEffect(() => {
    if (window.location.hash === '#/dashboard') {
      setSelectedKey('3');
    } else if (window.location.hash === '#/trash') {
      setSelectedKey('4');
    } else if (window.location.hash === '#/home') {
      setSelectedKey('1');
    } else if (window.location.hash === '#/external-drive') {
      setSelectedKey('5');
    }
  }, [window.location.hash]);

  const progressBar = (
    <Progress
      percent={getPercentage(user?.currentUsedSize || 0, user?.maxAllowedSize || 0)}
      showInfo={false}
      strokeColor="#E43D4F"
      strokeWidth={10}
    />
  );

  useEffect(() => {
    const googleDriveUsers = app.state?.googleDrive?.emails;
    if (googleDriveUsers?.length > 0) {
      setGoogleDriveSider(
        // <SubMenu key="5-googleDrive" icon={
        //   <img src="googleDriveLogo.png" alt="" aria-hidden="true" role="presentation" style={{ height: '16px', width: '16px' }}></img>
        // }
        //   disabled={isGoogleDriveUserLoading}
        //   title="Google Drive"
        // >
        <>
          {googleDriveUsers.map((email: string, index: number) => {
            return (
              <Menu.Item key={`5-googleDrive-${email}`} icon={
                <img src="googleDriveLogo.png" alt="" aria-hidden="true" role="presentation" style={{ height: '16px', width: '16px' }}></img>
              } onClick={() => {
                navigate('google-drive',
                  {
                    state: {
                      selectedDrive: DriveType.GOOGLE_DRIVE,
                      googleDrive:
                      {
                        type: DriveType.GOOGLE_DRIVE,
                        email: email,
                        key: `5-googleDrive-${email}`
                      }
                    }
                  }
                );
                setSelectedKey(`5-googleDrive-${email}`);
              }}>
                {email}
              </Menu.Item>
            );
          })}
        </>

      );
    } else {
      setGoogleDriveSider(null);
    }
  }, [app?.state?.googleDrive?.emails, fetchGoogleDriveUser]);

  const getSizeNotation = (size: number) => {
    let sizeNotation = 'B';
    if (size >= 1024) {
      size /= 1024;
      sizeNotation = 'KB';
    }
    if (size >= 1024) {
      size /= 1024;
      sizeNotation = 'MB';
    }
    if (size >= 1024) {
      size /= 1024;
      sizeNotation = 'GB';
    }
    return `${size.toFixed(2)} ${sizeNotation}`;
  }
  const openExternalLink = (url: string) => {
    //  open with electron
    // @ts-ignore
    if (window.electron && app?.state?.platformIsWeb) {
      // @ts-ignore
      window.electron.openExternalLink(url);
    } else {
      // @ts-ignore
      window?.open?.(url, '_blank');
    }
  }
  return (
    <Resizable
      size={{ width: width, height: "90vh" }}
      onResizeStop={(e, direction, ref, d) => {
        setWidth(width + d.width);
      }}
      minWidth={50}
      maxWidth={400}
      style={{ transition: 'width 0.2s' }}
    >
      <Sider
        trigger={null}
        collapsible
        collapsed={collapsed}
        onCollapse={toggleCollapsed}
        style={{
          overflow: 'scroll',
          minHeight: '100%',
          left: 0,
          backgroundColor: 'white',
          borderRight: '5px solid #f0f0f0',
          borderRadius: 16,
        }}
        width={width}
      >
        <Button
          type="primary"
          onClick={toggleCollapsed}
          style={{ marginBottom: 16, width: '100%', backgroundColor: '#E43D4F' }}>
          {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
        </Button>
        <Menu
          mode="inline"
          selectedKeys={[selectedKey]}
          defaultOpenKeys={['sub1']}
          style={{ height: '100%', borderRight: 0 }}
        >
          <Menu.Item
            key="1"
            icon={<HomeOutlined />}
            style={{ paddingBottom: 10, marginBottom: 10 }}
            onClick={() => {
              setSelectedKey('1');
              navigate('/home');
            }}
          >
            {t('pageSider.home')}
          </Menu.Item>
          <Menu.Item key="41" icon={<PopOutIcon />}>
            <Typography style={{ marginLeft: '8px' }}>
              {collapsed ? "" : t('pageSider.playGround')}
            </Typography>
          </Menu.Item>
          <SubMenu key="sub1" icon={<MyDriveIcon />} title={collapsed ? "" : t('pageSider.fileManager')}>
            <Menu.Item key="3" icon={<FolderOpenOutlined />} onClick={() => { app.setAppState({ ...app.state, searchTerm: null }); navigate('dashboard'); setSelectedKey('3'); }}>
              <Typography>{t('pageSider.myDrive')}</Typography>
            </Menu.Item>
            <Menu.Item key="31" disabled icon={<SharedFilesIcon />}>
              <Typography style={{ marginLeft: '8px', fontSize: '14px', color: 'GrayText' }}>
                {t('pageSider.sharedWithMe')}
              </Typography>
            </Menu.Item>
            <Menu.Item key="4" icon={<DeleteOutlined />} onClick={() => { navigate('trash'); setSelectedKey('4'); }}>
              {t('pageSider.trash')}
            </Menu.Item>
            <Menu.Item key="5" icon={<PlusOutlined />} onClick={() => {
              navigate('external-drive', {
                state: {
                  selectedDrive: null,
                  googleDrive: {
                    type: "GOOGLE_DRIVE"
                  }
                }
              });
              setSelectedKey('5');
            }}>
              {t('pageSider.connectStorage')}
            </Menu.Item>
            {googleDriveSider}
          </SubMenu>
          <Menu.SubMenu key="sub2" icon={<AppstoreOutlined />} title={t('pageSider.products')}>
            <Menu.Item key="6" onClick={() => openExternalLink('https://dev.cadian.com/product.php')} icon={<AppstoreOutlined />}>
              {t('pageSider.allProducts')}
            </Menu.Item>
            <Menu.Item key="8" onClick={() => openExternalLink('https://dev-mypage.cadian.com/product')} icon={<KeyOutlined />}>
              {t('pageSider.myLicenses')}
            </Menu.Item>
          </Menu.SubMenu>
          <Menu.SubMenu key="sub3" icon={<QuestionCircleOutlined />} title={t('pageSider.support')}>
            <Menu.Item key="10" onClick={() => openExternalLink('https://dev.cadian.com/board/board.php?bbsid=faq1')} icon={<BulbOutlined />}>
              {t('pageSider.faq')}
            </Menu.Item>
            <Menu.Item key="11" onClick={() => openExternalLink('https://dev.cadian.com/board/board.php?bbsid=qna')} icon={<QuestionOutlined />}>
              {t('pageSider.qna')}
            </Menu.Item>
            <Menu.Item key="12" onClick={() => openExternalLink('https://dev.cadian.com/board/board.php?bbsid=movie')} icon={<PlaySquareOutlined />}>
              {t('pageSider.video')}
            </Menu.Item>
          </Menu.SubMenu>
          {!collapsed && (
            <>
              <Menu.Divider />
              <Menu.Item key="sub4" style={{ minHeight: '10vh', minWidth: '17px', paddingLeft: '20px' }}>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <CloudTwoTone twoToneColor={'#E43D4F'} />
                  <Typography style={{ marginLeft: '8px' }}>{t('pageSider.storage')}</Typography>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  {/* Progress Bar Logic */}
                  {progressBar}
                  <Typography style={{ alignSelf: 'center', fontSize: '80%' }}>
                    {isLoading ? <Loading3QuartersOutlined /> : `${getSizeNotation(currentUserSize)} / ${getSizeNotation(maxUserSize)}`}
                  </Typography>
                </div>
              </Menu.Item>
            </>
          )}
        </Menu>
      </Sider>
    </Resizable>
  );
};

export default Sidebar;
