import React, { useState, useEffect, useLayoutEffect, useContext } from 'react';
import { Table, Tabs, Breadcrumb } from 'antd';
import { useInfiniteQuery } from 'react-query';
import { fetchPersonalFiles, fetchSharedFiles } from "../react-query/queries/googleDrive";
import moment from 'moment';
import { useLocation } from 'react-router-dom';
import { AppContext } from '../components/AppContext';
import { useTranslation } from 'react-i18next';

const { TabPane } = Tabs;

const GoogleFileExplorer = () => {
  const { app } = useContext<any>(AppContext);
  const { t, i18n } = useTranslation();
  // get location state from useLocation
  const location = useLocation();
  const state = location.state as any;
  const designatedUserEmail = state?.googleDrive?.email;

  if (app?.state?.setSelectedKey && !app?.state?.selectedKey?.includes(designatedUserEmail)) {
    app.state.setSelectedKey(`5-googleDrive-${designatedUserEmail}`);
  }
  const [currentPersonalFolder, setCurrentPersonalFolder] = useState(state?.googleDrive?.shared ? 'root' : (state?.googleDrive?.folderId || 'root'));
  const [currentSharedFolder, setCurrentSharedFolder] = useState(state?.googleDrive?.shared ? (state?.googleDrive?.folderId || null) : null);
  const [personalPath, setPersonalPath] = useState([{ id: 'root', name: t('googleDrive.googleDrive') }]);
  const [sharedPath, setSharedPath] = useState<any[]>([{ id: null, name: t('googleDrive.googleDrive') }]);
  const [personalPage, setPersonalPage] = useState(1);
  const [sharedPage, setSharedPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [personalTotal, setPersonalTotal] = useState(0);
  const [sharedTotal, setSharedTotal] = useState(0);

  const [activeTab, setActiveTab] = useState(state?.googleDrive?.shared ? '2' : '1');

  // Function to programmatically change the active tab
  const handleChangeTab = (key: string) => {
    console.log("key --> ", key)
    setActiveTab(key);
  };

  const {
    data: personalData,
    fetchNextPage: fetchNextPersonalPage,
    hasNextPage: hasNextPersonalPage,
    isFetching: isFetchingPersonal,
  } = useInfiniteQuery(
    ['personalFiles', currentPersonalFolder, designatedUserEmail],
    ({ pageParam = { folderId: currentPersonalFolder, pageToken: null } }) => fetchPersonalFiles({ pageParam, email: designatedUserEmail }),
    {
      getNextPageParam: (lastPage) => lastPage?.data?.nextPageToken ? { folderId: currentPersonalFolder, pageToken: lastPage?.data?.nextPageToken } : undefined,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );

  const {
    data: sharedData,
    fetchNextPage: fetchNextSharedPage,
    hasNextPage: hasNextSharedPage,
    isFetching: isFetchingShared,
  } = useInfiniteQuery(
    ['sharedFiles', currentSharedFolder, designatedUserEmail],
    ({ pageParam = { folderId: currentSharedFolder, pageToken: null } }) => fetchSharedFiles({ pageParam, email: designatedUserEmail }),
    {
      getNextPageParam: (lastPage) => lastPage.data.nextPageToken ? { folderId: currentSharedFolder, pageToken: lastPage.data.nextPageToken } : undefined,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );

  useLayoutEffect(() => {
    if (designatedUserEmail && !state?.googleDrive?.folderId) {
      setCurrentPersonalFolder('root');
      setCurrentSharedFolder(null);
      setPersonalPath([{ id: 'root', name: t('googleDrive.googleDrive') }]);
      setSharedPath([{ id: null, name: t('googleDrive.googleDrive') }]);
      setPersonalPage(1);
      setSharedPage(1);
      setPersonalTotal(0);
      setSharedTotal(0);
      for (let i = personalData?.pages.length || 0; i > 1; i--) {
        personalData?.pages.pop();
      }
      for (let i = sharedData?.pages.length || 0; i > 1; i--) {
        sharedData?.pages.pop();
      }
    }
  }, [designatedUserEmail]);

  const personalFiles = (personalData?.pages.flatMap(page => page.data.files) || []).filter(file => file.trashed === false);
  const sharedFiles = (sharedData?.pages.flatMap(page => page.data.files) || []).filter(file => file.trashed === false);


  useEffect(() => {
    if (personalData) {
      const totalFiles = personalData.pages.reduce((total, page) => total + page.data.files.length, 0);
      setPersonalTotal(totalFiles + (hasNextPersonalPage ? 1 : 0)); // Increment total if there are more pages to fetch
    }
  }, [personalData, hasNextPersonalPage]);

  useEffect(() => {
    if (sharedData) {
      const totalFiles = sharedData.pages.reduce((total, page) => total + page.data.files.length, 0);
      setSharedTotal(totalFiles + (hasNextSharedPage ? 1 : 0)); // Increment total if there are more pages to fetch
    }
  }, [sharedData, hasNextSharedPage]);

  const handlePersonalFolderClick = (record: any) => {
    if (record.mimeType === 'application/vnd.google-apps.folder') {
      setCurrentPersonalFolder(record.id);
      setPersonalPath([...personalPath, { id: record.id, name: record.name }]);
    }
  };

  const handleSharedFolderClick = (record: any) => {
    if (record.mimeType === 'application/vnd.google-apps.folder') {
      setCurrentSharedFolder(record.id);
      setSharedPath([...sharedPath, { id: record.id, name: record.name }]);
    }
  };

  const handleBreadcrumbClick = (path: any[], isShared = false) => {
    // console.log('Setting personal path to', path, isShared);
    if (isShared) {
      setSharedPath(path);
      const folderId = path[path.length - 1]?.id || null;
      setCurrentSharedFolder(folderId);
      setSharedPage(1);
      setSharedTotal(0);
      if (state?.googleDrive?.folderId && sharedPath.length === 1) {
        state.googleDrive.folderId = null;
        state.googleDrive.shared = false;
        setSharedPath([{ id: null, name: t('googleDrive.googleDrive') }]);
        setCurrentSharedFolder(null);
      }
    } else {
      console.log('here now');

      setPersonalPath(path);
      const folderId = path[path.length - 1]?.id || 'root';
      setCurrentPersonalFolder(folderId);
      setPersonalPage(1);
      setPersonalTotal(0);
      if (state?.googleDrive?.folderId && personalPath.length === 1) {
        state.googleDrive.folderId = null;
        setPersonalPath([{ id: 'root', name: t('googleDrive.googleDrive') }]);
        setCurrentPersonalFolder('root');
      }
    }
  };

  const handleTableChange = (pagination: any) => {

    if (pagination > personalPage && hasNextPersonalPage) {
      fetchNextPersonalPage().then(() => {
        setPersonalPage(pagination);
      });
    } else if (pagination < personalPage) {
      setPersonalPage(pagination);
      for (let i = personalData?.pages.length || 0; i > pagination; i--) {
        personalData?.pages.pop();
      }
      // personalData?.pages?.pop();
      const totalFiles = personalData?.pages.slice(0, pagination).reduce((total, page) => total + page.data.files.length, 0);
      setPersonalTotal(totalFiles + (hasNextPersonalPage ? 1 : 0));
    } else {
      setPersonalPage(pagination);
    }
  };

  const handleSharedTableChange = (pagination: any) => {
    if (pagination > sharedPage && hasNextSharedPage) {
      fetchNextSharedPage().then(() => setSharedPage(pagination));
    } else if (pagination < sharedPage) {
      setSharedPage(pagination);
      for (let i = sharedData?.pages.length || 0; i > pagination; i--) {
        sharedData?.pages.pop();
      }
      // sharedData?.pages?.pop();
      const totalFiles = sharedData?.pages.slice(0, pagination).reduce((total, page) => total + page.data.files.length, 0);
      setSharedTotal(totalFiles + (hasNextSharedPage ? 1 : 0));
    } else {
      setSharedPage(pagination);
    }
  };



  const columns = [
    {
      title: t('googleDrive.name'),
      dataIndex: 'name',
      key: 'name',
      render: (text: string, record: any) => (
        <a onClick={() => record.mimeType === 'application/vnd.google-apps.folder' ? handlePersonalFolderClick(record) : null}>{text}</a>
      ),
      sorter: (a: any, b: any) => a.name.localeCompare(b.name),
    },
    {
      title: t('googleDrive.modifiedDate'),
      dataIndex: 'modifiedTime',
      key: 'modifiedTime',
      sorter: (a: any, b: any) => moment(a.modifiedTime).unix() - moment(b.modifiedTime).unix(),
      render: (text: string) => {
        if (i18n.language === 'en') {
          // return Aug, 12 2021 12:00
          return moment(text)?.local()?.format('MMM DD, YYYY HH:mm')
        }
        else {

          return moment(text)?.local()?.format('YYYY-MM-DD HH:mm')
        }
      },
    },
    {
      title: t('googleDrive.format'),
      dataIndex: 'mimeType',
      key: 'mimeType',
      sorter: (a: any, b: any) => a.mimeType.localeCompare(b.mimeType),
      // add render appropriate for google drive
      render: (text: string) => {
        if (!text) return '';
        return text.split('.').pop();
      }
    },
    {
      title: t('googleDrive.size'),
      dataIndex: 'quotaBytesUsed',
      key: 'quotaBytesUsed',
      sorter: (a: any, b: any) => a.quotaBytesUsed - b.quotaBytesUsed,
      render: (text: string) => {
        if (!text) return '';
        const number = parseInt(text, 10);
        if (number < 1024) {
          return `${number} B`;
        } else if (number < 1024 * 1024) {
          return `${(number / 1024).toFixed(2)} KB`;
        } else if (number < 1024 * 1024 * 1024) {
          return `${(number / (1024 * 1024)).toFixed(2)} MB`;
        } else {
          return `${(number / (1024 * 1024 * 1024)).toFixed(2)} GB`;
        }
      },
    }
  ];

  const sharedColumns = [
    {
      title: t('googleDrive.name'),
      showSorterTooltip: false,
      dataIndex: 'name',
      key: 'name',
      render: (text: string, record: any) => (
        <a onClick={() => record.mimeType === 'application/vnd.google-apps.folder' ? handleSharedFolderClick(record) : null}>{text}</a>
      ),
    },
    {
      title: t('googleDrive.modifiedDate'),
      dataIndex: 'modifiedTime',
      key: 'modifiedTime',
      showSorterTooltip: false,
      sorter: (a: any, b: any) => moment(a.modifiedTime).unix() - moment(b.modifiedTime).unix(),
      render: (text: string) => {
        if (i18n.language === 'en') {
          // return Aug, 12 2021 12:00
          return moment(text)?.local()?.format('MMM DD, YYYY HH:mm')
        }
        else {

          return moment(text)?.local()?.format('YYYY-MM-DD HH:mm')
        }
      }
    },
    {
      title: t('googleDrive.format'),
      dataIndex: 'mimeType',
      key: 'mimeType',
      showSorterTooltip: false,
      render: (text: string) => {
        if (!text) return '';
        return text.split('.').pop();
      }
    },
    {
      title: t('googleDrive.size'),
      dataIndex: 'quotaBytesUsed',
      key: 'quotaBytesUsed',
      sorter: (a: any, b: any) => a.quotaBytesUsed - b.quotaBytesUsed,
      showSorterTooltip: false,
      render: (text: string) => {
        if (!text) return '';
        const number = parseInt(text, 10);
        if (number < 1024) {
          return `${number} B`;
        } else if (number < 1024 * 1024) {
          return `${(number / 1024).toFixed(2)} KB`;
        } else if (number < 1024 * 1024 * 1024) {
          return `${(number / (1024 * 1024)).toFixed(2)} MB`;
        } else {
          return `${(number / (1024 * 1024 * 1024)).toFixed(2)} GB`;
        }
      },
    }
  ];

  return (
    <Tabs activeKey={activeTab} onChange={(key: any) => handleChangeTab(key)}>
      <TabPane tab={t('googleDrive.personalFiles')} key="1">
        <Breadcrumb separator='>'>
          {personalPath.map((path, index) => (
            <Breadcrumb.Item key={path.id}>
              <a onClick={() => handleBreadcrumbClick(personalPath.slice(0, index + 1))}>{path.name}</a>
            </Breadcrumb.Item>
          ))}
        </Breadcrumb>
        <Table
          columns={columns}
          dataSource={personalFiles}
          rowKey="id"
          loading={isFetchingPersonal}
          pagination={{
            current: personalPage,
            pageSize: pageSize,
            total: personalTotal,
            onChange: handleTableChange,
            position: ['bottomCenter'],
            // showSizeChanger: true,
            // showTotal: (total) => `Total ${total} items`,
          }}
        />

      </TabPane>
      <TabPane tab={t('googleDrive.sharedWithMe')} key="2">
        <Breadcrumb separator='>'>
          {sharedPath.map((path, index) => (
            <Breadcrumb.Item key={path.id}>
              <a onClick={() => handleBreadcrumbClick(sharedPath.slice(0, index + 1), true)}>{path.name}</a>
            </Breadcrumb.Item>
          ))}
        </Breadcrumb>

        <Table
          columns={sharedColumns}
          dataSource={sharedFiles}
          rowKey="id"
          loading={isFetchingShared}
          pagination={{
            current: sharedPage,
            pageSize: pageSize,
            total: sharedTotal,
            onChange: handleSharedTableChange,
            position: ['bottomCenter'],
            // showSizeChanger: true,
            // showTotal: (total) => `Total ${total} items`,
          }}
        />

      </TabPane>
    </Tabs>
  );
};

export default GoogleFileExplorer;
