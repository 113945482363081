import axios from 'axios';
import { notification } from 'antd';
import { getRefreshedToken } from './queries/loginUser';


// Function to retrieve the auth token
const getAuthToken = async () => {
	if (process.env.REACT_APP_PLATFORM === 'web') {
		return localStorage.getItem('token');
	} else {
		// @ts-ignore
		return window?.electron.getStoreValue('token')
	}
};
;

const baseURL = {
	development: 'http://localhost:5001/api/v1/',
	staging: process.env.REACT_APP_API_FILE_SERVER_URL,
	production: process.env.REACT_APP_API_FILE_SERVER_URL,
	test: ""
}[process.env.REACT_APP_NODE_ENV || 'development'];  // Default to development if NODE_ENV is not set

const axiosInstance = axios.create({
	baseURL,
	headers: {
		'Content-Type': 'application/json'
	},
	timeout: 60000,
});

let isRefreshing = false;
let failedQueue: any[] = [];

const processQueue = (error: any, token = null) => {
	failedQueue.forEach(prom => {
		if (error) {
			prom.reject(error);
		} else {
			prom.resolve(token);
		}
	});

	failedQueue = [];
};


axiosInstance.defaults.withCredentials = true;

// Request interceptor for adding the authorization token to requests
axiosInstance.interceptors.request.use(
	async (config) => {
		const token = await getAuthToken()
		if (token) {
			config.headers['Authorization'] = `Bearer ${token}`;
		}
		return config;
	},
	error => Promise.reject(error)
);

async function logout() {
	if (process.env.REACT_APP_PLATFORM === 'web') {
		// Remove the user's token and other session data from browser
		localStorage.removeItem('token');
		// remove cookie 
		document.cookie = 'file_manager_signed_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
		window.location.href = window.location.origin + '/#login';

		return;
	}
	// Remove the user's token and other session data
	// localStorage.removeItem('token');
	// @ts-ignore
	await window.electron.removeCookie('file_manager_signed_token');
	// @ts-ignore
	await window.electron.removeStoreValue('token');
	// @ts-ignore
	await window.electron.removeStoreValue('refresh_token');

}

// Optionally, add a response interceptor to handle global errors or refresh tokens
axiosInstance.interceptors.response.use(
	response => response,
	async (error) => {
		console.log("===>>error: ", error.response.status)
		const originalRequest = error.config;

		if (error.response && error.response.status === 401 && !originalRequest._retry) {
			if (isRefreshing) {
				return new Promise(function (resolve, reject) {
					failedQueue.push({ resolve, reject });
				}).then(token => {
					originalRequest.headers['Authorization'] = 'Bearer ' + token;
					return axiosInstance(originalRequest);
				}).catch(err => {
					return Promise.reject(err);
				});
			}

			originalRequest._retry = true;
			isRefreshing = true;

			return new Promise(async (resolve, reject) => {
				try {
					const data = await getRefreshedToken();
					console.log("newtoken: ", data?.data.data)
					const newToken = data?.data.data.id_token
					if (!newToken) {
						throw new Error("Token not found")
					}
					if (process.env.REACT_APP_PLATFORM === 'web') {
						localStorage.setItem('token', newToken);
					} else {
						// @ts-ignore
						await window.electron.setStoreValue('token', newToken);
					}

					axiosInstance.defaults.headers.common['Authorization'] = 'Bearer ' + newToken;
					originalRequest.headers['Authorization'] = 'Bearer ' + newToken;
					processQueue(null, newToken);

					resolve(axiosInstance(originalRequest));
				} catch (err) {
					processQueue(err, null);
					await logout();
					window.location.href = window.location.origin + '/#login';
					reject(err);
				} finally {
					isRefreshing = false;
				}
			});
		}

		if (error.response && error.response.status === 500 && error.config.url?.includes('/callback')) {
			return;
		}

		console.error("axios error", error);
		const message = error.response?.data?.message || 'An unexpected error occurred';

		// notification.error({
		// 	message: 'Error',
		// 	description: message,
		// });

		return Promise.reject(error);
	}
);

export default axiosInstance;
