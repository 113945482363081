
import { useMutation, useQuery } from "react-query";
import axiosClient from '../axiosInstance';
import { FileList, File } from './types/files'
import { notification } from "antd";


export async function getTokenDesktop(code: string) {

	const url = `oauth/desktop-callback?code=${code}`;
	const result = await axiosClient.get(url);
	return result;

}
export async function getRefreshedToken() {
	let token;
	if (process.env.REACT_APP_PLATFORM === 'web') {
		token = localStorage.getItem('refresh_token');
		localStorage.removeItem('refresh_token');
	} else {
		// @ts-ignore
		token = await window.electron.getStoreValue('refresh_token');
		// @ts-ignore
		await window.electron.removeStoreValue('refresh_token');
	}

	// @ts-ignore
	if (!token) {
		// notification.error({
		// 	message: 'Error',
		// 	description: "Refresh token not found.",
		// });
		return;
	}

	const url = `oauth/refresh-token?refreshToken=${encodeURIComponent(token)}`;
	const result = await axiosClient.get(url);
	return result;

}