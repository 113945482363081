// src/i18n.ts
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './locales/eng/translation.json';
import kor from './locales/kor/translation.json';


i18n
    .use(initReactI18next)
    .init({
        lng: 'en',
        fallbackLng: 'en',
        resources: {
            en: {
                translation: en
            },
            kor: {
                translation: kor
            }
        },
        interpolation: {
            escapeValue: false, // React already does escaping
        }
    });

export default i18n;
