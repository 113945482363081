import { useMutation, useQueryClient } from "react-query";
import axiosInstance from "../axiosInstance";
import { notification } from "antd";


export function useCreateFolder() {
	const queryClient = useQueryClient();
	const mutation = useMutation({
		mutationKey: ["createFolder"],
		mutationFn: async ({ folderId, folderName }: { folderId: string | null, folderName: string }) => {
			const url = `file/create-folder`;
			const folder = await axiosInstance
				.post(url, { folderId, folderName })
				.then((r) => r.data);
			queryClient.invalidateQueries(["fileList", folderId])

			return folder;
		},
		onSuccess: () => {
			console.log("seccuess ==>>> ")
		},
		onError: (error: any) => {
			// show notification
			console.log("error", error);
			notification.error({
				message: 'Error',
				description: error?.message || "Something went wrong",
			});
		},

	});

	return mutation;
}
