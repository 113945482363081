import { Button, Modal, Space, notification, Input, Form, InputNumber, Empty, Breadcrumb, Tooltip } from "antd";
import Table, { ColumnType } from "antd/es/table";
import { SortOrder } from "antd/es/table/interface";
import moment from "moment";
import { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from "react";
import {
  FolderOutlined,
  FileOutlined,
  CheckCircleFilled,
  CloseCircleFilled,
} from '@ant-design/icons';

import { useTranslation, Trans } from 'react-i18next';
import ActionBar from "./tableActionBar"
import { Loader } from "../loader";
import { useRestoreTrashItem } from "../../react-query/mutations/restoreTrashItem";
import { useDeleteTrashItem } from "../../react-query/mutations/deleteTrash";


const FileList = (props: any, ref: any) => {

  const { t, i18n } = useTranslation();
  const { fileList, handleFolderClick, currentFolderId, currentFolder, onUpdate } = props;
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedAction, setSelectedAction] = useState(''); // Add this state to store the selected action
  const { mutateAsync: restoreFolder, isLoading: restoreFolderLoading } = useRestoreTrashItem('folder', currentFolderId);
  const { mutateAsync: restoreFile, isLoading: restoreFileLoading } = useRestoreTrashItem('file', currentFolderId);
  const { mutateAsync: deleteItem, isLoading: deleteItemLoading } = useDeleteTrashItem();

  useEffect(() => {
    if (selectedRowKeys.length === 0) {
      setSelectedAction('');
    }
  }, [selectedRowKeys]);

  const rowSelection = {
    selectedRowKeys,
    onChange: (newSelectedRowKeys: any) => {

      if (newSelectedRowKeys?.length > 1) {
        setSelectedRowKeys(newSelectedRowKeys.slice(-1));
      }
      else {
        setSelectedRowKeys(newSelectedRowKeys);
      }
    },
  };


  const handleDelete = async () => {
    try {
      await deleteItem({ itemId: selectedRowKeys[0], type: fileList.find((item: any) => item.id === selectedRowKeys[0]).type });
      await onUpdate();
    } catch (error) {
      console.log(error)
    }
  }

  const handleRestore = async () => {
    try {
      const type = fileList.find((item: any) => item.id === selectedRowKeys[0]).type;
      if (type === 'folder') {
        await restoreFolder({ itemId: selectedRowKeys[0] });
      } else {
        await restoreFile({ itemId: selectedRowKeys[0] });
      }
      await onUpdate();
    } catch (error) {

    }
  }

  const restoreConfirmation = async () => {
    const itemName = fileList.find((item: any) => item.id === selectedRowKeys[0]).name;
    Modal.confirm({
      title: t('trash.fileList.restoreConfirmationTitle'),
      content: (<Trans i18nKey="trash.fileList.restoreConfirmationContent" values={{ itemName }}></Trans>),  //t('trash.fileList.restoreConfirmationContent', { itemName }),
      okText: t('trash.fileList.yes'),
      okType: 'primary',
      cancelText: t('trash.fileList.no'),
      onOk() {
        handleRestore();
      },
      onCancel() {
        // Do nothing
      },
    });
  };
  // create a function getAction call which returns the action on click of the button
  const handleAction = async (action: string) => {
    switch (action) {
      case 'delete':
        const name = fileList.find((item: any) => item.id === selectedRowKeys[0]).name;
        Modal.confirm({
          title: t('trash.fileList.deleteConfirmationTitle'),
          content: (<Trans i18nKey="trash.fileList.deleteConfirmationContent" values={{ name: name }}></Trans>),// t('trash.fileList.deleteConfirmationContent', { name }),
          okText: t('trash.fileList.deleteForever'),
          okType: 'danger',
          cancelText: t('trash.fileList.cancel'),
          onOk() {
            handleDelete();
          },
          onCancel() {
            // Do nothing
          },
        });
        break;
      case 'restore':
        restoreConfirmation();
        break;
      default:
        break;
    }
  };


  const columns: Array<ColumnType<any>> = [
    {
      title: t('trash.fileList.name'),
      showSorterTooltip: false,
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
      render: (text, record) => (
        <Space>
          {record.type === 'folder' ? <FolderOutlined rev={undefined} /> : <FileOutlined rev={undefined} />}
          <Button type="link" onClick={() => { if (record.type === 'folder') handleFolderClick(record); }}>
            {text}
          </Button>
        </Space>
      ),
    },
    {
      title: t('trash.fileList.originalLocation'),
      showSorterTooltip: false,
      dataIndex: 'lastSystemPathFolderNames',
      key: 'lastSystemPathFolderNames',
      sorter: false,
      render: (text, record) => {

        const prefix = 'My Drive';
        if (!text && !currentFolder?.lastSystemPathFolderNames) return prefix;
        else if (!text && currentFolder?.lastSystemPathFolderNames) {
          // find the parent folder and return the path
          if (currentFolder?.lastSystemPathFolderNames?.endsWith('/')) {
            text = currentFolder?.lastSystemPathFolderNames + record?.name + '/';
          } else {
            text = currentFolder?.lastSystemPathFolderNames + '/' + record?.name + '/';
          }
        }
        const pathParts = text.split('/')?.slice ? text.split('/').slice(1, text.split('/').length - 2) : [];
        return (
          <Breadcrumb separator='>'>
            <Breadcrumb.Item>{prefix}</Breadcrumb.Item>
            {pathParts.map((part: any, index: any) => (
              <Breadcrumb.Item key={index}>{part}</Breadcrumb.Item>
            ))}
          </Breadcrumb>
        );
      },
    },
    {
      title: t('trash.fileList.deletedDate'),
      showSorterTooltip: false,
      dataIndex: 'deletedAt',
      key: 'deletedAt',
      defaultSortOrder: 'descend' as SortOrder,
      sorter: (a, b) => moment(a.deletedAt).unix() - moment(b.deletedAt).unix(),
      render: (text: string) => {
        if (i18n.language === 'en') {
          // return Aug, 12 2021 12:00
          return moment(text)?.local()?.format('MMM DD, YYYY HH:mm')
        }
        else {

          return moment(text)?.local()?.format('YYYY-MM-DD HH:mm')
        }
      },
    },
    {
      title: t('trash.fileList.format'),
      showSorterTooltip: false,
      dataIndex: 'mimeType',
      key: 'format',
      sorter: (a, b) => a.mimeType?.split('/')[1]?.localeCompare(b.mimeType?.split('/')[1]),
      render: (text, record) => {
        if (!text) return '';
        return record?.name?.split ? record?.name?.split('.').slice(-1).join('.') : '';
      }
    },
    {
      title: t('trash.fileList.size'),
      showSorterTooltip: false,
      dataIndex: 'size',
      key: 'size',
      sorter: (a, b) => a.size - b.size,
      render: (text) => {
        if (!text) return '';
        if (text < 1024) return `${text} B`;
        if (text < 1024 * 1024) return `${(text / 1024).toFixed(2)} KB`;
        if (text < 1024 * 1024 * 1024) return `${(text / (1024 * 1024)).toFixed(2)} MB`;
        return `${(text / (1024 * 1024 * 1024)).toFixed(2)} GB`;
      },
    },
  ];


  return (
    <>
      {selectedRowKeys.length === 1 && <ActionBar selectedCount={selectedRowKeys.length} setSelectedAction={setSelectedAction} selectedAction={selectedAction} performAction={handleAction} />}
      <Loader isLoading={restoreFolderLoading || restoreFileLoading || deleteItemLoading} children={null} />
      <Table dataSource={fileList} columns={columns} rowSelection={rowSelection}
        pagination={
          {
            pageSize: 10,
            showSizeChanger: false,
            showQuickJumper: false,
            // showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
            position: ['bottomCenter'],
          }
        }
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={
                <span>
                  <p>{t('trash.fileList.noData')}</p>
                  <p>{t('trash.fileList.trashInfo')}</p>
                </span>
              }
            />
          ),
        }} />
    </>)

};

export default FileList;