import { ReactComponent as SharedFiles } from './sharedFiles.svg'; // The path to your svg file
import { ReactComponent as MyDrive } from './myDrive.svg'; // The path to your svg file
import { ReactComponent as PlaySvg } from './play.svg'; // The path to your svg file
import { ReactComponent as ShoppingBag } from './shopping-bag.svg'; // The path to your svg file
import { ReactComponent as Logout } from './logout.svg'; // The path to your svg file


export const SharedFilesIcon = () => {
  return <SharedFiles />;
};

export const ShoppingBagIcon = () => {
  return <ShoppingBag />;
};

export const LogoutIcon = () => {
  return <Logout />;
};

export const MyDriveIcon = () => {
  return <MyDrive />;
};

export const PlayIcon = () => {
  return <PlaySvg style={{ color: '#E43D4F' }} />;
};

export const PopOutIcon = () => {
  return (
    <>
      <style>
        {`
          @keyframes combined-animation {
              0% {
                  transform: rotate(0deg) ;
              }
              50% {
                  transform: rotate(360deg); /* Begin pop-out */
              }

							60% {
                  transform: rotate(360deg) scale(1.3) ; /* End pop-out */
              }
              80% {
                  transform: rotate(360deg) scale(0.8) ; /* End pop-out */
              }
              85% {
                  transform: rotate(360deg) scale(1) ; /* End pop-out */
              }
              80% {
                  transform: rotate(360deg) scale(1.2) ; /* End pop-out */
              }
              100% {
                  transform: rotate(360deg) scale(1) ; /* End pop-out */
              }
          }


          .pop-out-icon {
              display: inline-block;
              animation: combined-animation 2s infinite cubic-bezier(0.4, 0, 1, 1);
          }
        `}
      </style>
      <svg className="pop-out-icon" width="1.3em" height="1.3em" viewBox="2 0 24 24" fill="#E43D4F" xmlns="http://www.w3.org/2000/svg">
        <path d="M21.4086 9.35258C23.5305 10.5065 23.5305 13.4935 21.4086 14.6474L8.59662 21.6145C6.53435 22.736 4 21.2763 4 18.9671L4 5.0329C4 2.72368 6.53435 1.26402 8.59661 2.38548L21.4086 9.35258Z" />
      </svg>
    </>
  );
};
