// data = {
//   "folders": [
//     {
//       "name": "string_test_3",
//       "owner": "661e806e36091dea19a1a939",
//       "parent": null,
//       "shared": false,
//       "deletedAt": null,
//       "systemPath": "/",
//       "lastSystemPath": null,
//       "createdDateTime": "2024-04-22T05:03:00.139Z",
//       "modifiedDateTime": "2024-04-22T05:03:00.139Z",
//       "id": "6625ef8464fd392e59f50f91"
//     }
//   ],
//     "files": [
//       {
//         "name": "Untitled design.png",
//         "owner": "661e806e36091dea19a1a939",
//         "folder": null,
//         "mimeType": "image/png",
//         "size": 10949,
//         "shared": false,
//         "bucketPath": "/fileSystem/661e806e36091dea19a1a939/563a098cf71e42638b9a541d3b0a07c2.png",
//         "systemPath": "/",
//         "lastSystemPath": null,
//         "createdDateTime": "2024-04-16T13:44:26.364Z",
//         "modifiedDateTime": "2024-04-16T13:44:26.365Z",
//         "id": "661e80ba36091dea19a1a94f"
//       },
//       {
//         "name": "WhatsApp Image 2024-04-15 at 11.56.22 AM.jpeg",
//         "owner": "661e806e36091dea19a1a939",
//         "folder": null,
//         "mimeType": "image/jpeg",
//         "size": 125886,
//         "shared": false,
//         "bucketPath": "/fileSystem/661e806e36091dea19a1a939/90382d06ca3a416fbd70530a9bb9c4f6.jpeg",
//         "systemPath": "/",
//         "lastSystemPath": null,
//         "createdDateTime": "2024-04-17T16:03:11.500Z",
//         "modifiedDateTime": "2024-04-17T16:03:11.500Z",
//         "id": "661ff2bfb61090a5d50da973"
//       }
//     ]
// }
export const createDirectoryList = (data: any) => {
  if (!data) {
    return [];
  }
  const folders = data.folders.map((folder: any) => ({
    type: 'folder',
    key: folder.id,
    ...folder
  }));

  const files = data.files.map((file: any) => ({
    type: 'file',
    key: file.id,
    ...file
  }));

  return [...folders, ...files];
};

export const buildDirectoryStructure = (data: any) => {
  const root: any = [];

  data.forEach((file: any) => {
    const parts = file.Key.split('/');
    let currentLevel = root; // Start at the root

    // Go through all parts of the key (which are folders or the file itself)
    parts.forEach((part: any, index: any) => {
      // Check if the part is already present at the current level in the hierarchy
      let existingPart = currentLevel.find((item: any) => item.name === part);
      let count_ = 100;
      if (existingPart) {
        // If we find the folder, go one level deeper
        currentLevel = existingPart._children;
      } else {
        // If it's not found, create a new folder or file entry
        if (index < parts.length - 1) {
          // Not yet at the end, so it's a folder
          const newFolder = {
            name: part,
            type: 'folder',
            _children: [],
            size: '',
            lastModified: '',
            key: count_ + part,
            format: '',
            downloadLink: '',
          };
          count_ += 1;
          currentLevel.push(newFolder);
          currentLevel = newFolder._children;
        } else {
          // It's a file
          const newFile = {
            name: file.originalName,
            type: 'file',
            size: file.Size,
            lastModified: file.LastModified,
            key: file.Key,
            format: file.Key.split('.').pop(),
            downloadLink: `https://cadian-dev-user.kr.object.ncloudstorage.com/${file.Key}`, // Adjust the download link according to your needs
          };
          currentLevel.push(newFile);
        }
      }
    });
  });

  return root;
};

export const randomUUID = () => {
  return `${Math.random().toString(36).substring(7)}`;
}

export const bytesToGigabytes = (bytes: number, fixed: number, roundUp: boolean = false): number => {
  const calc = parseFloat((bytes / (1024 ** 3)).toFixed(fixed))
  return roundUp ? Math.ceil(calc) : calc;
};

export const getPercentage = (used: number, total: number): number => {
  if (total === 0) return 0; // Prevent division by zero
  const percentage = (used / total) * 100;
  return parseFloat(percentage.toFixed(2)); // Ensures the function returns a number with 2 decimal places
};