
import { useMutation, useQuery } from "react-query";
import axiosClient from '../axiosInstance';
import { notification } from "antd";

export const fetchPersonalFiles = async ({ pageParam = { folderId: 'root', pageToken: null }, email }:
	{
		pageParam: { folderId: string, pageToken: string | null },
		email: string
	}) => {
	const { folderId, pageToken } = pageParam;
	const response = await axiosClient.get('google-drive/personal-files', {
		params: {
			folderId,
			pageToken,
			pageSize: 10,
			email
		},
	});
	return response.data;
};

export const fetchSharedFiles = async ({ pageParam = { folderId: null, pageToken: null }, email }:
	{
		pageParam: { folderId: string | null, pageToken: string | null },
		email: string
	}) => {
	const { folderId, pageToken } = pageParam;
	const response = await axiosClient.get('google-drive/shared-with-me', {
		params: {
			folderId,
			pageToken,
			pageSize: 10,
			email
		},
	});
	return response.data;
};
export function useGetGoogleDriveUserLazyLoad() {
	const mutation = useMutation(
		async () => {
			const url = `google-drive/auth-status`;
			const result = await axiosClient.get(url);
			return result.data;
		},
		{
			onError: (error: any) => {
				console.log("error", error);
			},
		}
	);

	return mutation;
}


export const useGoogleDriveGetUser = () => {
	const query = useQuery(
		["getGooGleDriveUser"],
		async () => {
			const url = `google-drive/auth-status`;
			const result = await axiosClient.get(url).then((r) => r.data);
			return result.data;
		},
		{
			enabled: true,
			refetchOnMount: false,
			refetchOnWindowFocus: false,
			onError: (error) => {
				console.log("error", error);
				return {
					data: false,
					isSuccess: false,
				};
			},
			onSuccess: (data) => {
			},
		}
	);

	return query;
};