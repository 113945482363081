import { useMutation, useQueryClient } from "react-query";
import axiosInstance from "../axiosInstance";
import { notification } from "antd";


export function useCopyItem(type: string, folderId: string | null) {
	const mutation = useMutation({
		mutationKey: ["CopyItem", folderId],
		mutationFn: async ({ itemId, parentFolderId }: { itemId: string, parentFolderId: string | null }) => {
			const url = type === 'folder' ? `file/copy-folder` : `file/copy-file`;

			const folder = await axiosInstance
				.post(url, {
					folderId: itemId,
					fileId: itemId,
					parentFolderId: parentFolderId
				})
				.then((r) => r.data);
			return folder;
		},
		onSuccess: () => {
		},
		onError: (error: any) => {
			// show notification
			notification.error({
				message: 'Error',
				description: error?.message || "Something went wrong",
			});
		},

	});

	return mutation;
}
