import { useCallback, useEffect, useLayoutEffect, useState, useContext } from 'react';
import { useGoogleAuthLink, useGoogleAuthCallback, useDeleteGoogleAccount } from "../react-query/mutations/googleDrive";
import StorageConnectList from "../components/ExternalDrives/drivesList";
import { DriveType, DriveTypes } from '../components/ExternalDrives/types';
import { AppContext, IAppContextValue } from '../components/AppContext';

const ExternalDrivesPage = () => {
	const { app } = useContext(AppContext) as IAppContextValue;
	const googleAuthLink = useGoogleAuthLink();
	const googleCallbackMutation = useGoogleAuthCallback();
	const deleteGoogleAccount = useDeleteGoogleAccount();
	const handleConnectClick = async () => {

		const { data, isSuccess } = await googleAuthLink.mutateAsync();

		// check if data is a valid url
		try {
			const url = new URL(data);
		}
		catch (e) {
			console.log("invalid url");
			return;
		}
		if (app.state.platformIsWeb) {
			// redirect to the url
			window.location.href = data;
			return;
		}
		// @ts-ignore
		window.electron.openGoogleAuth(data);
	};
	const handleAuthCode = useCallback(async (data: any) => {
		if (data?.error) {
			console.log("error: ", data.error);
			return;
		}
		if (data?.code) {
			const resp = await googleCallbackMutation.mutateAsync({ code: data?.code });
			// invalidate getGooGleDriveUser query
			// queryClient.invalidateQueries();
			// const fetchedData = await fetchGoogleDriveUserData();
			// setGoogleDriveUser(fetchedData.data);
			app?.state?.googleDrive?.refresh()
		}
	}, []);

	useLayoutEffect(() => {
		if (app.state.platformIsWeb) {
			// user will be redirected to http://localhost:3000/google/callback
			const url = new URL(window.location.href);
			// extract query params from the url
			const urlSplitted = url?.href?.split("?")[1]?.split("&");
			const code = urlSplitted?.find((param) => param.includes("code"))?.split("=")[1];
			if (code) {
				handleAuthCode({ code }).then(() => {
					// remove all query params from the url
					window.location.href = window.location.origin + "/#external-drive";
				});
			}
		}
		else {
			// @ts-ignore
			window.electron.onGoogleCallback(handleAuthCode);

			return () => {
				// ipcRenderer.removeAllListeners('google-callback');
				// @ts-ignore
				window.electron.removeGoogleCallbackListener(handleAuthCode);

			};
		}
	}, []);

	const connectDrive = (driveType: DriveTypes) => {
		// switch case for different drive types
		switch (driveType) {
			case DriveType.GOOGLE_DRIVE:
				handleConnectClick();
				break;
			case DriveType.DROPBOX:
				break;
			case DriveType.ONE_DRIVE:
				break;
		}
	}
	const storageConnectData = app.state?.googleDrive?.emails ? {
		googleDrive: {
			type: DriveType.GOOGLE_DRIVE,
			emails: app.state?.googleDrive?.emails,
			key: '5-googleDrive'
		}
	} : null;

	const deleteDrive = async (driveType: DriveTypes, email: string) => {
		switch (driveType) {
			case DriveType.GOOGLE_DRIVE:
				deleteGoogleAccount.mutate(email);
				//  remove email from the state
				const filteredEmails = app?.state?.googleDrive?.emails?.filter((_email: string) => _email !== email);
				app.setAppState({ ...app.state, googleDrive: { ...(app?.state?.googleDrive), emails: filteredEmails } });
				break;
			case DriveType.DROPBOX:
				break;
			case DriveType.ONE_DRIVE:
				break;
		}
		// filter the email which was removed
		// const filteredEmails = app?.state?.googleDrive?.emails?.filter((_email: string) => _email !== email);
		// app.setAppState({ ...app.state, googleDrive: { emails: filteredEmails, ...(app?.state?.googleDrive) } });
	}
	return (
		<div>
			{
				<StorageConnectList deleteAccount={deleteDrive} connectDrive={connectDrive} data={storageConnectData}
					loading={googleCallbackMutation.isLoading || googleAuthLink.isLoading || deleteGoogleAccount.isLoading || app?.state?.isLoading === true} />
			}
		</div>
	);
};
export default ExternalDrivesPage;
