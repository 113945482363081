import { Layout, theme } from 'antd';
import React from 'react';
import { Outlet } from 'react-router-dom'; // Import Outlet
import PageHeader from './PageHeader';
import PageSider from './PageSider';

interface IPageLayout {
  // children: React.ReactElement;
}

export default function PageLayout(props: IPageLayout) {
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  return (
    <Layout>
      <PageHeader />
      <Layout>
        <PageSider />
        <Layout style={{ padding: '0 4px 4px' }}>
          <Layout.Content
            style={{
              padding: 24,
              margin: 0,
              minHeight: 600,
              background: colorBgContainer,
              borderRadius: borderRadiusLG,
            }}
          >
            {/* {props.children} */}
            <Outlet /> 
          </Layout.Content>
        </Layout>
      </Layout>
    </Layout>
  );
}
