import { Mutation, useMutation } from "react-query";
import axiosInstance from "../axiosInstance";



const enum LanguagePreference {
    'en' = 'en',
    'ko' = 'ko'
}
export function useUpdateUserLangPreference() {
    const mutation = useMutation(async (langPreference: LanguagePreference) => {

        const response = await axiosInstance
            .post('file/update-user-language-preference', { lang: langPreference })
            .then((r) => r.data);
        return response;
    }
    );
    return mutation;
}
