
import { useQuery } from "react-query";
import axiosClient from '../axiosInstance';
import { useMutation } from 'react-query';
import { notification } from "antd";

export function useTrashFilesList(folderId: string | null) {
	const query = useQuery(
		["trashFileList", folderId],
		async () => {
			const url = folderId ? `file/trash/${folderId}` : `file/trash/null`;
			const result = await axiosClient.get(url).then((r) => r.data);
			return result.data;
		},
		{
			enabled: true,
			refetchOnWindowFocus: false,

			onError: (error: any) => {
				// show notification
				console.log("error", error);
				notification.error({
					message: 'Error',
					description: error?.message || "Something went wrong",
				});
			}
		}
	);

	return query;
}

