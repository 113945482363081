import { Layout, Typography, Button } from 'antd';
import { min } from 'moment';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
const { Header, Content, Footer } = Layout;
const { Title } = Typography;

const Home = () => {
  const { t } = useTranslation();
  const headerStyle = {
    backgroundImage: `url('DesktopAppHomeClip.gif')`, // Set GIF as background image
    backgroundSize: 'cover', // Cover the entire header area
    backgroundPosition: 'center center', // Center the background image
    backgroundRepeat: 'no-repeat', // Do not repeat the background image
    display: 'flex', // Ensures the contents of the header are aligned correctly
    alignItems: 'center', // Aligns items vertically
    justifyContent: 'center', // Centers items horizontally
    color: 'white', // Ensures text is white for visibility
    minHeight: '20vh', // Set a minimum height for the header
  };

  return (
    <Layout className="layout">
      <Header style={headerStyle}>
      </Header>
      <Content style={{ padding: '0 50px' }}>
        <div className="site-layout-content">
          <Title level={2}>{t('home.welcomeTitle')}</Title>
          <Typography>{t('home.description')}</Typography>
          <Button type="primary" size="large" style={{ backgroundColor: '#E43D4F', color: 'white', marginTop: '16px' }}>
            <Link to="/dashboard" style={{ color: 'white' }}>{t('home.goToDashboard')}</Link>
          </Button>
        </div>
      </Content>
      <Footer style={{ textAlign: 'center' }}>{t('home.footer')}</Footer>
    </Layout>
  );
};

export default Home;
