import { useMutation, useQueryClient } from "react-query";
import axiosInstance from "../axiosInstance";
import { notification } from "antd";


export function useRestoreTrashItem(type: string, folderId: string | null) {
	const mutation = useMutation({
		mutationKey: ["CopyItem", folderId],
		mutationFn: async ({ itemId }: { itemId: string }) => {
			const url = type === 'folder' ? `file/restore-folder` : `file/restore-file`;

			const response = await axiosInstance
				.post(url, {
					folderId: itemId,
					fileId: itemId
				})
				.then((r) => r.data);
			return response;
		},
		onSuccess: () => {
			console.log("seccuess ==>>> ")
		},
		onError: (error: any) => {

			console.log("error", error);
			notification.error({
				message: 'Error',
				description: error?.message || "Something went wrong",
			});
		},

	});

	return mutation;
}
