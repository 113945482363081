import { Breadcrumb, Space } from "antd";

const BreadCrumbs = ({ breadcrumbs, title, onClick }: { breadcrumbs: any[], title: string, onClick: (index: number) => void }) => {
  let breadcrumbItems = breadcrumbs.map((crumb, index) => ({
    title: crumb.name,
    key: index + 1, // Generally, using index as a key is not recommended, consider using a unique ID if possible
  }));
  return (
    <Space style={{ marginBottom: 16 }}>
      <Breadcrumb separator='>' >
        <Breadcrumb.Item key={0} >
          <a onClick={() => onClick(-1)} >{title}</a>
        </Breadcrumb.Item>
        {breadcrumbItems.map((item) => (
          <Breadcrumb.Item key={item.key}>
            <a onClick={() => onClick(item.key)}>{item.title}</a>
          </Breadcrumb.Item>
        ))}
      </Breadcrumb>
    </Space>
  );
};

export default BreadCrumbs;