import { useMutation } from 'react-query';
import axiosInstance from '../axiosInstance';

const useUploadFile = () => {
	const mutation = useMutation(async (fileData: { file: File, folderId: string }) => {
		const { file, folderId } = fileData;
		let fileType = file.type;
		if (fileType === 'application/dxf') {
			fileType = 'image/vnd.dxf';
		}
		// new instance of file
		const filCopy = new File([file], file.name, { type: fileType });
		const formData = new FormData();
		formData.append('fileData', filCopy);
		formData.append('folderId', folderId); // Adjust according to your needs

		const response = await axiosInstance.post('file/upload', formData, {
			headers: {
				'accept': 'application/json',
				'Content-Type': 'multipart/form-data'
			}
		});
		return response?.data || '';
	});
	return mutation;
};

export default useUploadFile;
