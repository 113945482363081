import React, { useState, useEffect } from 'react';
import { Modal, Button, List, message, Typography, Divider, Space, Input } from 'antd';
import { FolderOpenOutlined, FileOutlined, CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { useFilesListLazyLoad } from '../../react-query/queries/fetchFiles';
import { createDirectoryList } from '../../utils/common/helpers';
import { Loader } from '../loader';
import { ArrowLeftOutlined, FolderAddOutlined } from '@ant-design/icons'
import './styles/styles.css';
import { useCreateFolder } from '../../react-query/mutations/postCreateFolder';
import { useTranslation } from 'react-i18next';

function FolderNavigation({ isVisible, onCancel, onMove, itemName, itemId, actionName }: any) {
  const { t } = useTranslation();
  const [folders, setFolders] = useState<any[]>([]);
  const [selectedFolder, setSelectedFolder] = useState<any>(null);
  const fileListFetcher = useFilesListLazyLoad();
  const [folderHistory, setFolderHistory] = useState<string[]>([]);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [newFolderName, setNewFolderName] = useState<string>('');
  const [currentFolderId, setCurrentFolderId] = useState<string | null>(null);
  const { mutateAsync: createFolder, isLoading: folderCreationLoading } = useCreateFolder();

  // Fetch initial data
  useEffect(() => {
    if (isVisible) {
      cleanModal();
      fetchFolders(null);
    }
  }, [isVisible]);

  const fetchFolders = async (folderId: string | null) => {
    try {
      setCurrentFolderId(folderId);
      const { data } = await fileListFetcher.mutateAsync(folderId)
      const structured = createDirectoryList(data);
      setFolders(structured);
    } catch (error) {
      console.log("Error ", error)
      // message.error('Failed to fetch folders');
    }
  };

  const handleBackClick = () => {
    if (folderHistory?.length === 0) {
      fetchFolders(null);
      return;
    };
    const newHistory = [...folderHistory];
    newHistory.pop(); // Remove the current folder
    setFolderHistory((prev: any) => [...newHistory])
    const previousFolderId = newHistory.pop(); // Get the previous folder
    fetchFolders(previousFolderId as string | null);
  };

  const handleFolderDoubleClick = (folderId: string | null) => {
    setFolderHistory((prev: any) => [...prev, folderId])
    fetchFolders(folderId);
  };

  const handleFolderClick = (folder: any) => {
    if (folder?.type === 'folder') {
      setSelectedFolder(folder);
    }
  };

  const cleanModal = () => {
    setFolders([]);
    setSelectedFolder(null);
    setFolderHistory([]);
    setIsEditing(false);
    setNewFolderName('');
    setCurrentFolderId(null);
  }
  const cleanAndCancel = () => {
    cleanModal();
    onCancel();
  }

  const cleanBeforeMove = () => {
    cleanModal();
    onMove(selectedFolder?.id || null);
  }
  const getCurrentFolderName = () => {
    // if(folderHistory?.length === 0) return "Parent Folder";
    // if(folderHistory.includes(selectedFolder?.id)){
    // 	return selectedFolder.name;
    // }

    // return selectedFolder?.name;
    return ""
  }

  const onAddNewFolder = async () => {
    if (isEditing) return;
    setIsEditing(true);
    setFolders((prevFolders) => [
      ...prevFolders,
      { id: Math.random().toString(), name: '', type: 'folder', isEditing: true },
    ]);
  };

  const handleFolderNameChange = (e: React.ChangeEvent<HTMLInputElement>, id: string) => {
    const newName = e.target.value;
    setFolders((prevFolders) =>
      prevFolders.map((folder) =>
        folder.id === id ? { ...folder, name: newName } : folder
      )
    );
  };

  const handleSaveFolder = async (id: string) => {
    setFolders((prevFolders) =>
      prevFolders.map((folder) =>
        folder.id === id ? { ...folder, isEditing: false } : folder
      )
    );
    await createFolder({ folderId: currentFolderId, folderName: newFolderName })
    await fetchFolders(currentFolderId);

  };

  const handleCancelEdit = (id: string) => {
    setIsEditing(false);
    setNewFolderName('');
    setFolders((prevFolders) =>
      prevFolders.filter((folder) => folder.id !== id || !folder.isEditing)
    );
  };
  return (
    <Modal
      title={(<Typography>{`${actionName === 'move' ? t('dashboard.actionBar.move') : t('dashboard.actionBar.copy')} ${itemName}`}</Typography>)}
      open={isVisible}
      onCancel={cleanAndCancel}
      footer={[
        <Button key="cancel" onClick={cleanAndCancel}>
          {t('common.cancel')}
        </Button>,
        <Button
          key="move"
          type="primary"
          onClick={() => cleanBeforeMove()}
          // disabled={selectedFolder}
          style={{ backgroundColor: '#E0475A', borderColor: '#E0475A' }}
        >
          {actionName === 'move' ? t('dashboard.actionBar.move') : t('dashboard.actionBar.copy')}
        </Button>,
      ]}
    >
      <Divider plain style={{ color: 'black', backgroundColor: 'black' }} />
      <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
        {/* Adjusted for space-between */}
        <Loader isLoading={fileListFetcher.isLoading || folderCreationLoading} children={null} />
        <Space style={{}}>
          <Button key="back" onClick={handleBackClick} disabled={folderHistory.length === 0}>
            <ArrowLeftOutlined /> {t('dashboard.folderNavigation.back')}
          </Button>
          <Typography>{getCurrentFolderName()}</Typography>
        </Space>

        {/* Button for new folder */}

        <Button
          onClick={(e: any) => {
            onAddNewFolder();
          }}
          icon={<FolderAddOutlined rev={undefined} />}
          style={{ marginLeft: 'auto' }}
          disabled={isEditing}
        >
          {t('dashboard.folderNavigation.newFolder')}
        </Button>

      </div>
      <Divider plain style={{ color: 'black', backgroundColor: 'black' }} />
      <List
        itemLayout="horizontal"
        dataSource={folders}
        renderItem={(item: any) => (
          <List.Item
            onClick={() => handleFolderClick(item)}
            onDoubleClick={() => {
              if (item.type === 'folder') {
                handleFolderDoubleClick(item.id)
              }
            }}
            className={`list-item ${selectedFolder?.id === item.id ? "selected-item" : ""}`}
            style={{ cursor: (item.type === 'folder' && itemId !== item.id) ? 'pointer' : 'not-allowed', color: (item.type === 'folder' && itemId !== item.id) ? 'black' : 'silver' }}
          >
            <List.Item.Meta
              avatar={(item.type === 'folder') ? <FolderOpenOutlined /> : <FileOutlined disabled />}
              title={
                item.isEditing ? (
                  <Input
                    value={newFolderName}
                    onChange={(e) => setNewFolderName(e.target.value)}
                  />
                ) : (
                  <Typography style={{ color: item.type === 'folder' ? 'black' : 'silver' }}>{item.name}</Typography>
                )
              }
            />
            {item.isEditing && (
              <Space style={{ marginLeft: '8px' }}>
                <Button>
                  <CheckOutlined style={{ color: 'green' }} onClick={() => handleSaveFolder(item.id)} />
                </Button>
                <Button>
                  <CloseOutlined style={{ color: '#E43D4F' }} onClick={() => handleCancelEdit(item.id)} />
                </Button>
              </Space>
            )}
          </List.Item>
        )}
      />

    </Modal>
  );
}

export default FolderNavigation;
