import { useMutation, useQueryClient } from "react-query";
import axiosInstance from "../axiosInstance";
import { notification } from "antd";


export function useCleanTrash() {
	const mutation = useMutation({
		mutationKey: ["clearTrash"],
		mutationFn: async () => {
			const url = `file/delete-trash`;

			const respose = await axiosInstance
				.delete(url)
				.then((r) => r.data);
			return respose;
		},
		onSuccess: () => {
			console.log("seccuess ==>>> ")
		},
		onError: (error: any) => {
			// show notification
			console.log("error", error);
			notification.error({
				message: 'Error',
				description: error?.message || "Something went wrong",
			});
		},

	});

	return mutation;
}

export function useDeleteTrashItem() {
	const mutation = useMutation({
		mutationKey: ["clearTrash"],
		mutationFn: async ({ itemId, type }: { itemId: string, type: string }) => {
			const url = `file/delete-trash-${type === "file" ? "file" : "folder"}/${itemId}`;

			const respose = await axiosInstance
				.delete(url)
				.then((r) => r.data);
			return respose;
		},
		onSuccess: () => {
			console.log("seccuess ==>>> ")
		},
		onError: (error: any) => {
			// show notification
			console.log("error", error);
			notification.error({
				message: 'Error',
				description: error?.message || "Something went wrong",
			});
		},

	});

	return mutation;
}
